import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './file-field.css';

class FileField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { input: { onChange } } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const { meta: { touched, error }, accept } = this.props;

    return (
      <div className="file-field">
        <input
          type="file"
          accept={accept}
          onChange={this.onChange}
        />
        <small className="file-field__error">
          {touched && error ? error : ''}
        </small>
      </div>
    );
  }
}

export default FileField;

FileField.propTypes = {
  input: PropTypes.shape({ onChange: PropTypes.func.isRequired }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  accept: PropTypes.string,
};

FileField.defaultProps = {
  accept: undefined,
};
