import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import TipsProvide from '../TipsProvide';
import FeedbackPreviewItem from '../FeedbackPreviewItem/FeedbackPreviewItem';
import FeedbackOverallResults from '../FeedbackOverallResults/FeedbackOverallResults';
import SubmitField from '../SubmitField';
import InputField from '../InputField';
import TextAreaField from '../TextAreaField';
import SelectField from '../SelectField';
import validators from '../../utils/validators';
import i18next from '../../utils/i18next';

import './feedback-provide-form.css';

import cheerUp from '../../assets/images/cheer-up.png';
import visibility from '../../assets/images/visibility.svg';
import anonimity from '../../assets/images/anonimity.svg';

const AVAILABLE_LANGUAGES = process.env.REACT_APP_AVAILABLE_LANGUAGES;

class FeedbackProvideForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      started: false,
      reviewed: false,
      finished: false,
      questionNumber: 0,
      answers: [],
    };

    this.handlePreviousQuestion = this.handlePreviousQuestion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { answers } = this.state;

    onSubmit(answers).then(() => this.setState({ finished: true }));
  }

  handleNextQuestion(values) {
    const {
      questions,
      reset,
      change,
    } = this.props;
    const { questionNumber, answers } = this.state;

    const newAnswer = {
      question: questions[questionNumber].id,
      rating: values.rating,
      comment: values.comment,
    };
    const filteredAnswers = answers.filter(a => a.question !== newAnswer.question);

    window.scrollTo(0, 0);

    this.setState(
      { answers: [...filteredAnswers, newAnswer] },
      () => {
        reset();
        if ((questions.length - 1 === questionNumber)) {
          this.setState({ reviewed: true });
          return;
        }

        this.setState({ questionNumber: questionNumber + 1 });
        change('questionNumber', questionNumber + 1);

        const questionId = questions[questionNumber + 1].id;
        const answer = answers.filter(a => a.question === questionId)[0];
        if (answer) {
          change('rating', answer.rating);
          change('comment', answer.comment);
        }
      },
    );
  }

  handlePreviousQuestion() {
    const {
      questions,
      change,
      reset,
      rating,
      comment,
    } = this.props;
    const { answers, questionNumber, reviewed } = this.state;

    window.scrollTo(0, 0);

    const newAnswer = {
      question: questions[questionNumber].id,
      rating,
      comment,
    };
    const filteredAnswers = answers.filter(a => a.question !== newAnswer.question);

    if (questionNumber < 1 && !reviewed) {
      this.setState({ started: false });
      return;
    }

    const previousQuestionNumber = reviewed ? questionNumber : questionNumber - 1;
    this.setState({
      reviewed: false,
      questionNumber: previousQuestionNumber,
      answers: [...filteredAnswers, newAnswer],
    });

    const questionId = questions[previousQuestionNumber].id;
    const answer = answers.filter(a => a.question === questionId)[0];

    reset();
    change('rating', answer.rating);
    change('comment', answer.comment);
    change('questionNumber', previousQuestionNumber);
  }

  renderRating() {
    const {
      t,
      change,
      rating,
      questions,
      questionNumber,
      selfAssessment,
    } = this.props;

    let scale = _.get(questions, `${questionNumber}.scale`, []);
    scale = _.keyBy(scale, 'value');
    const customScale = !_.isEmpty(scale);

    const questionText = _.get(questions, `${questionNumber}.text`, '');
    const reverseOrder = questionText === 'question.upwards_feedback.question_1_v2'
      || questionText === 'question.upwards_feedback.question_1_v3';

    const settings = [
      {
        enabled: !customScale || !!_.get(scale, '5', null),
        value: 5,
        className: 'i-strongly-agree',
        // eslint-disable-next-line no-nested-ternary
        text: (customScale && selfAssessment)
          ? t(`${_.get(scale, '5.text', 'answer.label.rating_5')}_self_assessment`)
          : (customScale
            ? t(_.get(scale, '5.text', 'answer.label.rating_5'))
            : t('answer.label.rating_5')
          ),
        icon: (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="#3BB44A"
                d="M79.237,20.769C63.117,4.648,36.89,4.648,20.766,20.765C4.649,36.885,4.649,63.112,20.773,79.232
                        c16.117,16.12,42.344,16.12,58.46,0S95.35,36.885,79.237,20.769z M74.726,74.724c-13.631,13.635-35.813,13.635-49.448,0
                        c-13.634-13.634-13.626-35.816,0-49.451c13.634-13.63,35.817-13.63,49.452,0.004C88.355,38.907,88.355,61.094,74.726,74.724z
                        M34.581,38.809c0-2.667,2.166-4.829,4.833-4.829c2.667,0,4.833,2.162,4.833,4.829c0,2.67-2.166,4.833-4.833,4.833
                        C36.747,43.642,34.581,41.479,34.581,38.809z M56.492,38.809c0-2.667,2.166-4.829,4.833-4.829s4.833,2.162,4.833,4.829
                        c0,2.67-2.166,4.833-4.833,4.833S56.492,41.479,56.492,38.809z M67.916,58.6c-2.996,6.927-10.009,11.401-17.867,11.401
                        c-8.028,0-15.079-4.497-17.973-11.458c-0.494-1.191,0.072-2.562,1.26-3.056c0.298-0.12,0.596-0.177,0.901-0.177
                        c0.909,0,1.785,0.539,2.154,1.44c2.166,5.21,7.522,8.575,13.657,8.575c5.994,0,11.317-3.368,13.574-8.582
                        c0.513-1.185,1.89-1.731,3.074-1.215C67.878,56.042,68.429,57.415,67.916,58.6z"
              />
            </g>
          </svg>
        ),
      },
      {
        enabled: !customScale || !!_.get(scale, '4', null),
        value: 4,
        className: 'i-agree',
        // eslint-disable-next-line no-nested-ternary
        text: (customScale && selfAssessment)
          ? t(`${_.get(scale, '4.text', 'answer.label.rating_4')}_self_assessment`)
          : (customScale
            ? t(_.get(scale, '4.text', 'answer.label.rating_4'))
            : t('answer.label.rating_4')
          ),
        icon: (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <g>
                  <path
                    fill="#8CC640"
                    d="M79.233,20.768c-16.116-16.12-42.344-16.12-58.46-0.004c-16.124,16.121-16.124,42.347,0,58.471
                              c16.116,16.116,42.344,16.116,58.46-0.004S95.35,36.885,79.233,20.768z M74.729,74.724c-13.634,13.63-35.816,13.63-49.451,0.004
                              c-13.634-13.639-13.626-35.825,0-49.451c13.634-13.634,35.817-13.634,49.451,0C88.355,38.907,88.355,61.093,74.729,74.724z
                              M34.581,38.813c0-2.671,2.166-4.836,4.829-4.836c2.671,0,4.836,2.165,4.836,4.836c0,2.671-2.166,4.829-4.836,4.829
                              C36.747,43.642,34.581,41.483,34.581,38.813z M56.492,38.813c0-2.671,2.165-4.836,4.829-4.836c2.671,0,4.836,2.165,4.836,4.836
                              c0,2.671-2.165,4.829-4.836,4.829C58.657,43.642,56.492,41.483,56.492,38.813z"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#8CC640"
                  d="M50.615,69.163c-6.678,0-13.348-1.672-19.437-5.021c-1.335-0.732-1.826-2.411-1.094-3.75
                           c0.74-1.336,2.415-1.822,3.758-1.087c10.503,5.776,23.05,5.776,33.553,0c1.344-0.735,3.019-0.249,3.75,1.087
                           c0.74,1.339,0.249,3.018-1.086,3.75C63.97,67.491,57.292,69.163,50.615,69.163z"
                />
              </g>
            </g>
          </svg>
        ),
      },
      {
        enabled: !customScale || !!_.get(scale, '3', null),
        value: 3,
        className: 'i-partly-agree',
        // eslint-disable-next-line no-nested-ternary
        text: (customScale && selfAssessment)
          ? t(`${_.get(scale, '3.text', 'answer.label.rating_3')}_self_assessment`)
          : (customScale
            ? t(_.get(scale, '3.text', 'answer.label.rating_3'))
            : t('answer.label.rating_3')
          ),
        icon: (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <g>
                  <path
                    fill="#C8C8C8"
                    d="M78.996,20.521C62.874,4.401,36.648,4.401,20.533,20.517c-16.123,16.119-16.123,42.348,0,58.467
                              c16.115,16.119,42.341,16.119,58.463-0.004C95.111,62.865,95.111,36.636,78.996,20.521z M74.484,74.476
                              c-13.633,13.63-35.814,13.634-49.447,0c-13.633-13.633-13.633-35.818,0-49.447c13.633-13.633,35.814-13.633,49.447,0
                              C88.118,38.662,88.118,60.843,74.484,74.476z M34.347,38.563c0-2.667,2.158-4.832,4.829-4.832c2.664,0,4.829,2.161,4.829,4.832
                              c0,2.667-2.165,4.832-4.829,4.832C36.505,43.396,34.347,41.23,34.347,38.563z M56.249,38.563c0-2.667,2.166-4.832,4.837-4.832
                              c2.67,0,4.828,2.161,4.828,4.832c0,2.667-2.158,4.832-4.828,4.832C58.415,43.396,56.249,41.23,56.249,38.563z"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#C8C8C8"
                  d="M70.056,65.792H30.703c-1.524,0-2.761-1.233-2.761-2.761c0-1.524,1.237-2.758,2.761-2.758h39.353
                           c1.524,0,2.754,1.233,2.754,2.758C72.81,64.559,71.58,65.792,70.056,65.792z"
                />
              </g>
            </g>
          </svg>
        ),
      },
      {
        enabled: !customScale || !!_.get(scale, '2', null),
        value: 2,
        className: 'i-disagree',
        // eslint-disable-next-line no-nested-ternary
        text: (customScale && selfAssessment)
          ? t(`${_.get(scale, '2.text', 'answer.label.rating_2')}_self_assessment`)
          : (customScale
            ? t(_.get(scale, '2.text', 'answer.label.rating_2'))
            : t('answer.label.rating_2')
          ),
        icon: (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <g>
                  <path
                    fill="#E3202C"
                    d="M78.99,20.521C62.874,4.4,36.647,4.4,20.531,20.517c-16.12,16.12-16.12,42.347,0,58.471
                              c16.116,16.116,42.343,16.116,58.459-0.004C95.113,62.863,95.113,36.637,78.99,20.521z M74.485,74.476
                              c-13.634,13.63-35.816,13.634-49.446,0c-13.63-13.634-13.63-35.816,0-49.451c13.63-13.63,35.813-13.63,49.446,0.004
                              C88.119,38.659,88.115,60.846,74.485,74.476z M34.338,38.561c0-2.667,2.165-4.829,4.836-4.829c2.667,0,4.833,2.162,4.833,4.829
                              c0,2.67-2.166,4.836-4.833,4.836C36.503,43.397,34.338,41.231,34.338,38.561z M56.249,38.561c0-2.667,2.165-4.829,4.837-4.829
                              c2.667,0,4.828,2.162,4.828,4.829c0,2.67-2.161,4.836-4.828,4.836C58.414,43.397,56.249,41.231,56.249,38.561z"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#E3202C"
                  d="M68.012,66.572c-0.316,0-0.648-0.057-0.974-0.178c-10.744-4.063-22.582-4.063-33.33,0
                           c-1.426,0.54-3.018-0.181-3.557-1.607c-0.543-1.426,0.181-3.021,1.607-3.557c12.004-4.539,25.23-4.539,37.235,0
                           c1.426,0.535,2.146,2.131,1.606,3.557C70.181,65.893,69.129,66.572,68.012,66.572z"
                />
              </g>
            </g>
          </svg>
        ),
      },
      {
        enabled: !customScale || !!_.get(scale, '1', null),
        value: 1,
        className: 'i-strongly-disagree',
        // eslint-disable-next-line no-nested-ternary
        text: (customScale && selfAssessment)
          ? t(`${_.get(scale, '1.text', 'answer.label.rating_1')}_self_assessment`)
          : (customScale
            ? t(_.get(scale, '1.text', 'answer.label.rating_1'))
            : t('answer.label.rating_1')
          ),
        icon: (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="100px"
            height="100px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill="#87171A"
                d="M78.997,20.52C62.874,4.4,36.648,4.4,20.532,20.516c-16.123,16.12-16.123,42.349,0,58.468
                        c16.116,16.12,42.342,16.12,58.465-0.004C95.113,62.865,95.113,36.636,78.997,20.52z M74.485,74.477
                        c-13.634,13.63-35.815,13.634-49.449,0s-13.633-35.819,0-49.449c13.633-13.633,35.815-13.633,49.449,0
                        C88.119,38.662,88.119,60.843,74.485,74.477z M34.339,38.563c0-2.667,2.166-4.833,4.836-4.833c2.663,0,4.829,2.162,4.829,4.833
                        c0,2.667-2.166,4.833-4.829,4.833C36.505,43.396,34.339,41.23,34.339,38.563z M56.25,38.563c0-2.667,2.165-4.833,4.836-4.833
                        s4.828,2.162,4.828,4.833c0,2.667-2.157,4.833-4.828,4.833S56.25,41.23,56.25,38.563z M31.85,66.46
                        c2.995-6.926,10.012-11.399,17.866-11.399c8.027,0,15.082,4.496,17.972,11.456c0.498,1.192-0.068,2.562-1.26,3.056
                        c-0.295,0.121-0.597,0.182-0.898,0.182c-0.912,0-1.788-0.544-2.157-1.441c-2.166-5.214-7.522-8.578-13.656-8.578
                        c-5.991,0-11.317,3.368-13.573,8.582c-0.513,1.185-1.886,1.731-3.071,1.219C31.887,69.021,31.336,67.645,31.85,66.46z"
              />
            </g>
          </svg>
        ),
      },
    ];

    const answers = settings
      .filter(s => s.enabled)
      .sort((a, b) => (reverseOrder ? a.value - b.value : b.value - a.value))
      .map(s => (
        <button
          key={s.value}
          className={`btn survey-question ${s.className} ${rating === s.value ? 'selected' : ''}`}
          type="button"
          onClick={() => change('rating', rating === s.value ? null : s.value)}
        >
          <i className="survey-question-icon">
            {s.icon}
          </i>
          {s.text}
        </button>
      ));

    return (
      <div>
        {answers}
        <Field
          name="rating"
          type="hidden"
          component={InputField}
        />
      </div>
    );
  }

  renderStart() {
    const {
      t,
      type,
      selfAssessment,
      name,
      userName,
      showBack,
      change,
      dueDate,
      initiator,
    } = this.props;
    const languageOptions = AVAILABLE_LANGUAGES
      .split(',')
      .map(language => (
        {
          value: language,
          label: t(`language.${language}`),
        }
      ));

    return (
      <div>
        <div className="content-box accordion">
          {userName !== null && (
            <p>
              {t('survey.label.hi')}
              {' '}
              {userName}
              ,
            </p>
          )}
          <ReactMarkdown>
            {t(`answer.label.tips_${type}${selfAssessment ? '_self' : ''}_1`)
              .replace(/<end date>/g, dueDate.format(t('date_format')))}
          </ReactMarkdown>
          <div className="row visibility-anonimity">
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={visibility}
                    alt="visibility"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {
                      t(`answer.label.tips_${type}${selfAssessment ? '_self' : ''}_2`)
                        .replace(/<feedback receiver>/g, name)
                        .replace(/<scrum master>/g, initiator)
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="col my-auto">
              <div className="row">
                <div className="col-sm-4 my-auto">
                  <img
                    src={anonimity}
                    alt="anonimity"
                  />
                </div>
                <div className="col-sm my-auto">
                  <p>
                    {t(`answer.label.tips_${type}${selfAssessment ? '_self' : ''}_3`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <TipsProvide />
        </div>
        <div className="buttons-group">
          {!showBack && (
            <div className="mb-4">
              <div className="m-4">
                <Field
                  name="language"
                  label={t('welcome.label.paragraph_2')}
                  showLabel
                  options={languageOptions}
                  onValueChange={language => i18next.changeLanguage(language).catch()}
                  component={SelectField}
                />
              </div>
            </div>
          )}
          <div className="buttons-group-wrapper">
            {showBack && (
              <Link
                className="btn standard gray"
                to="/"
              >
                {t('survey.action.cancel')}
              </Link>
            )}
            {!showBack && (
              <a
                className="btn standard gray"
                href="https://coca-colahellenic.com/"
              >
                {t('survey.action.cancel')}
              </a>
            )}
            <button
              className="btn standard"
              type="button"
              disabled={!showBack}
              onClick={() => { this.setState({ started: true }); change('questionNumber', 0); }}
            >
              {t('survey.action.next')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const {
      t,
      questions,
      selfAssessment,
      handleSubmit,
    } = this.props;
    const { questionNumber } = this.state;

    const questionTranslationKey = selfAssessment
      ? `${questions[questionNumber].text}_self_assessment`
      : questions[questionNumber].text;
    let questionText = t(questionTranslationKey);
    if (!questionText) {
      questionText = questionTranslationKey;
    }
    if (questionText) {
      questionText = questionText.split('<br>');
    }

    return (
      <form
        noValidate
        onSubmit={handleSubmit(this.handleNextQuestion.bind(this))}
      >
        <div className="content-box accordion">
          {questionText.map(value => (
            <p key={value}>
              <strong>
                {value}
              </strong>
            </p>
          ))}
          {questions[questionNumber].rating ? this.renderRating() : ''}
          <div className="comment-section">
            <div className="form-group">
              <Field
                name="comment"
                label={t('answer.label.comment')}
                component={TextAreaField}
              />
            </div>
          </div>
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper">
            <button
              className="d-inline-block btn standard gray"
              type="button"
              onClick={this.handlePreviousQuestion}
            >
              {t('survey.action.previous')}
            </button>
            <Field
              className="btn standard"
              inline
              name="submit"
              label={t('feedback.action.next')}
              component={SubmitField}
            />
          </div>
        </div>
      </form>
    );
  }

  renderReview() {
    const { t, questions, selfAssessment } = this.props;
    const { answers } = this.state;

    const overallRating = _.groupBy(answers, 'rating');
    const allQuestions = _.values(questions).map((question, index) => {
      const allAnswers = _.keyBy(answers, 'question');
      const answer = _.get(allAnswers, question.id);
      const hasAnswer = answer && (answer.rating > 0 || answer.comment);

      if (question.optional && !hasAnswer) {
        return null;
      }

      if (answer == null) {
        return (
          <div key={question.id} />
        );
      }

      return (
        <div key={question.id}>
          <FeedbackPreviewItem
            index={index}
            question={question.text}
            scale={question.scale}
            answer={answer.comment}
            rating={answer.rating}
            selfAssessment={selfAssessment}
          />
        </div>
      );
    });

    return (
      <div className="reports">
        <FeedbackOverallResults
          rating1={_.get(overallRating, 1, []).length}
          rating2={_.get(overallRating, 2, []).length}
          rating3={_.get(overallRating, 3, []).length}
          rating4={_.get(overallRating, 4, []).length}
          rating5={_.get(overallRating, 5, []).length}
        />
        <div className="report-accordion feed-back-list">
          {allQuestions}
        </div>
        <div className="buttons-group">
          <div className="buttons-group-wrapper">
            <button
              className="d-inline-block btn standard gray"
              type="button"
              onClick={this.handlePreviousQuestion}
            >
              {t('survey.action.previous')}
            </button>
            <button
              className="d-inline-block btn standard"
              type="button"
              onClick={this.handleSubmit}
            >
              {t('feedback.action.submit')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFinished() {
    const { t, showBack } = this.props;

    return (
      <div>
        <div className="content-box accordion">
          <p align="center">
            <strong>
              {t('answer.label.thank_you')}
              !
            </strong>
          </p>
          <p align="center">
            {t('answer.label.thank_you_desc')}
          </p>
          <div className="cheer-up">
            <img
              align="center"
              alt="cheer up"
              src={cheerUp}
            />
          </div>
        </div>
        {showBack && (
          <div className="buttons-group">
            <Link
              className="btn standard"
              to="/#collapseFour"
            >
              {t('menu.label.close')}
            </Link>
          </div>
        )}
        {!showBack && (
          <div className="buttons-group">
            <a
              className="btn standard"
              href="https://coca-colahellenic.com/"
            >
              {t('menu.label.close')}
            </a>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      t,
      questions,
      year,
      quarter,
      type,
      name,
      dueDate,
      title,
    } = this.props;
    const {
      started,
      reviewed,
      finished,
      questionNumber,
    } = this.state;

    if (!questions || questions.length === 0) {
      return <div />;
    }

    return (
      <div className="feedback-provide-form">
        <div className="content-title">
          <div className="col my-auto">
            <span className="box-title">
              {year}
              {' '}
              Q
              {quarter}
              {' '}
              {title || ''}
              {' '}
              {t(`survey.type.${type}`)}
              {' '}
              {t('survey.label.for')}
              {' '}
              {name}
            </span>
            <div className="information">
              {finished && (
                <span className="box-information">
                  <strong>
                    {t('answer.label.finished')}
                  </strong>
                </span>
              )}
              {!finished && (
                <span className="box-information">
                  <strong>
                    {t('answer.label.questions')}
                    :
                    {' '}
                  </strong>
                  {started && (
                    <div className="d-inline-block">
                      {questionNumber + 1}
                      {'/'}
                      {questions.length}
                    </div>
                  )}
                  {!started && (
                    <div className="d-inline-block">
                      {questions.length}
                    </div>
                  )}
                </span>
              )}
              {!finished && !started && (
                <span className="box-information">
                  <strong>
                    {t('survey.date.due')}
                    :
                    {' '}
                  </strong>
                  <div className="d-inline-block">
                    {dueDate.format(t('date_format'))}
                  </div>
                </span>
              )}
            </div>
          </div>
        </div>
        {!started && !reviewed && !finished && this.renderStart()}
        {started && !reviewed && !finished && this.renderForm()}
        {started && reviewed && !finished && this.renderReview()}
        {started && reviewed && finished && this.renderFinished()}
      </div>
    );
  }
}

const selector = formValueSelector('feedbackProvideForm');

const validate = (values, { t, questions, type }) => {
  const errors = {};
  const question = questions && Object.prototype.hasOwnProperty.call(values, 'questionNumber')
    ? questions[values.questionNumber]
    : null;

  if (
    question
    && !question.optional
    && (
      !validators.required(values.rating)
      || !validators.isNumber(values.rating)
      || !validators.min(values.rating, 1)
    )
  ) {
    errors.rating = t('answer.error.rating_required');
  }

  if (
    question
    && !question.rating
    && !validators.required(values.comment)
    && validators.equals(type, 'freestyle_feedback')
  ) {
    errors.comment = t('answer.error.comment_required');
  }

  return errors;
};

const mapStateToProps = (state) => {
  const rating = selector(state, 'rating');
  const comment = selector(state, 'comment');
  const questionNumber = selector(state, 'questionNumber');
  let initialValues;

  if (state.customerExternal) {
    initialValues = {
      language: state.customerExternal.language,
    };
  }

  return {
    rating,
    comment,
    questionNumber,
    initialValues,
  };
};

export default withNamespaces()(connect(mapStateToProps, null)(
  reduxForm({
    validate,
    form: 'feedbackProvideForm',
  })(FeedbackProvideForm),
));

FeedbackProvideForm.propTypes = {
  t: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  quarter: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selfAssessment: PropTypes.bool,
  rating: PropTypes.number,
  comment: PropTypes.string,
  questionNumber: PropTypes.number,
  dueDate: PropTypes.shape({}),
  title: PropTypes.string,
  showBack: PropTypes.bool,
  userName: PropTypes.string,
  initiator: PropTypes.string,
};

FeedbackProvideForm.defaultProps = {
  questions: [],
  selfAssessment: false,
  rating: 0,
  comment: '',
  questionNumber: 0,
  showBack: false,
  dueDate: moment(),
  title: '',
  userName: null,
  initiator: null,
};
