import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import SubmitField from '../../../components/SubmitField';
import DatePickerField from '../../../components/DatePickerField';
import AutocompleteSelectField from '../../../components/AutocompleteSelectField';
import { createSurvey } from '../../../redux-modules/survey/actions';
import fetchWorkforceSegment from '../../../redux-modules/workforce-segment/actions';
import fetchBusinessUnit from '../../../redux-modules/business-unit/actions';
import fetchUsers from '../../../redux-modules/user/actions';
import validators from '../../../utils/validators';

import './admin-create-survey-form.css';

class AdminCreateSurveyForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    const {
      fetchBusinessUnit: fetchBusinessUnitAction,
      fetchWorkforceSegment: fetchWorkforceSegmentAction,
      fetchUsers: fetchUsersAction,
      token,
    } = this.props;

    fetchBusinessUnitAction(token);
    fetchWorkforceSegmentAction(token);
    fetchUsersAction(token);
  }

  handleCancel() {
    const { onCancel, reset } = this.props;

    reset();
    onCancel();
  }

  handleSubmit(values) {
    const {
      token,
      createSurvey: createSurveyAction,
      onSubmit,
      reset,
    } = this.props;

    const launchDate = moment.utc().set({
      year: values.launchDate.year,
      month: parseInt(values.launchDate.month, 10) - 1,
      date: values.launchDate.day,
      hour: 2,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).format();
    const endDate = moment().utc().set({
      year: values.endDate.year,
      month: parseInt(values.endDate.month, 10) - 1,
      date: values.endDate.day,
      hour: 20,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).format();

    return createSurveyAction(
      token,
      values.quarter,
      values.year,
      '',
      values.type,
      [],
      [],
      values.segment ? values.segment : '',
      values.businessUnit ? values.businessUnit : '',
      launchDate,
      endDate,
      true,
      values.targets ? values.targets.map(v => v.value) : [],
    ).then(
      () => {
        reset();
        onSubmit();
      },
      () => {},
    );
  }

  render() {
    const {
      t,
      handleSubmit,
      workforceSegment,
      businessUnit,
      users,
      lastSyncDate,
    } = this.props;

    const workforceSegmentOptions = (workforceSegment || []).map(w => ({
      label: w.name,
      value: w.id,
    }));

    const businessUnitOptions = (businessUnit || []).map(b => ({
      label: b.name,
      value: b.id,
    }));

    return (
      <div className="admin-create-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <Field
            name="type"
            showErrors={false}
            label={t('survey.label.type')}
            placeholder={t('survey.placeholder.any_type')}
            options={[
              {
                label: t('survey.type.upwards_feedback'),
                value: 'upwards_feedback',
              },
              {
                label: t('survey.type.customer_feedback'),
                value: 'customer_feedback',
              },
              {
                label: t('survey.type.peer_feedback'),
                value: 'peer_feedback',
              },
            ]}
            component={SelectField}
          />
          <Field
            name="segment"
            showErrors={false}
            hasEmpty
            label={t('survey.label.workforce_segment')}
            placeholder={t('survey.placeholder.workforce_segment')}
            options={workforceSegmentOptions}
            component={SelectField}
          />
          <Field
            name="businessUnit"
            showErrors={false}
            hasEmpty
            label={t('user.label.business_unit')}
            placeholder={t('survey.placeholder.business_unit')}
            options={businessUnitOptions}
            component={SelectField}
          />
          <Field
            name="targets"
            showErrors={false}
            hasEmpty
            label={t('user.label.select_user')}
            options={users || []}
            component={AutocompleteSelectField}
          />
          <div className="form-group">
            <div className="row">
              <div className="col">
                <Field
                  name="year"
                  type="number"
                  showErrors={false}
                  label={t('survey.label.year')}
                  placeholder={t('survey.placeholder.year')}
                  component={InputField}
                />
              </div>
              <div className="col quarter">
                <Field
                  name="quarter"
                  showErrors={false}
                  label={t('survey.label.quarter')}
                  placeholder={t('survey.placeholder.quarter')}
                  options={[
                    {
                      label: 'Q1',
                      value: 1,
                    },
                    {
                      label: 'Q2',
                      value: 2,
                    },
                    {
                      label: 'Q3',
                      value: 3,
                    },
                    {
                      label: 'Q4',
                      value: 4,
                    },
                  ]}
                  component={SelectField}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <Field
              name="launchDate"
              showErrors={false}
              label={t('survey.label.launch_date')}
              component={DatePickerField}
            />
          </div>
          <div className="form-group">
            <Field
              name="endDate"
              showErrors={false}
              label={`${t('survey.label.end_date')} (${t('survey.label.reminder24h')})`}
              component={DatePickerField}
            />
          </div>
          {lastSyncDate && (
            <div className="form-group text-center">
              <span className={moment().diff(moment(lastSyncDate), 'days') > 0 ? 'text-danger' : 'text-success'}>
                Data feed was last updated
                {' '}
                {moment(lastSyncDate).fromNow()}
                {'.'}
              </span>
            </div>
          )}
          <div className="form-group text-center mb-2 mt-4">
            <button
              className="btn standard gray"
              type="button"
              onClick={this.handleCancel}
            >
              {t('survey.action.cancel')}
            </button>
            <Field
              inline
              name="submit"
              label={t('survey.action.launch')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.type)) {
    errors.type = t('survey.error.type_required');
  }

  if (!validators.required(values.quarter)) {
    errors.quarter = t('survey.error.quarter_required');
  }

  if (!validators.required(values.year)) {
    errors.year = t('survey.error.year_required');
  }

  if (!(values.launchDate
    && values.launchDate.day && values.launchDate.month && values.launchDate.year)
    || (values.launchDate
      && !validators.date(values.launchDate.month, values.launchDate.day, values.launchDate.year))
    || (values.launchDate.year < 1900)
  ) {
    errors.launchDate = t('survey.error.date');
  }

  if (!(values.endDate
    && values.endDate.day && values.endDate.month && values.endDate.year)
    || (values.endDate
      && !validators.date(values.endDate.month, values.endDate.day, values.endDate.year))
    || (values.endDate.year < 1900)
  ) {
    errors.endDate = t('survey.error.date');
  }

  return errors;
};

const mapStateToProps = ({
  token,
  workforceSegment,
  businessUnit,
  users,
  lastSyncDate,
}) => ({
  token,
  workforceSegment,
  businessUnit,
  users,
  lastSyncDate,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createSurvey,
  fetchWorkforceSegment,
  fetchBusinessUnit,
  fetchUsers,
}, dispatch);

export default withNamespaces()(reduxForm({
  validate,
  form: 'adminCreateSurveyForm',
})(connect(mapStateToProps, mapDispatchToProps)(AdminCreateSurveyForm)));

AdminCreateSurveyForm.propTypes = {
  t: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  workforceSegment: PropTypes.arrayOf(PropTypes.shape({})),
  businessUnit: PropTypes.arrayOf(PropTypes.shape({})),
  createSurvey: PropTypes.func.isRequired,
  fetchWorkforceSegment: PropTypes.func.isRequired,
  fetchBusinessUnit: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  lastSyncDate: PropTypes.string,

};

AdminCreateSurveyForm.defaultProps = {
  workforceSegment: null,
  businessUnit: null,
  users: null,
  lastSyncDate: null,
};
