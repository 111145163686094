import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';
import $ from 'jquery';

import Tooltip from '../Tooltip';
import RatingsStackedBar from '../RatingsStackedBar';

import ratingUtils from '../../utils/ratings';

class SurveyReportItem extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  componentDidMount() {
    $(this.showComments).on('show.bs.collapse', this.handleShow);
    $(this.showComments).on('hide.bs.collapse', this.handleHide);

    const { showComments } = this.props;

    if (showComments) {
      this.handleShow();
    }
  }

  componentWillUnmount() {
    $(this.showComments).off('show.bs.collapse', this.handleShow);
    $(this.showComments).off('hide.bs.collapse', this.handleHide);
  }

  handleShow() {
    this.setState({ open: true });
  }

  handleHide() {
    this.setState({ open: false });
  }

  render() {
    const {
      t,
      id,
      index,
      showComments,
      questionText,
      rating1,
      rating2,
      rating3,
      rating4,
      rating5,
      scale,
      comments,
      providers,
      selfAssessment,
    } = this.props;
    const { open } = this.state;

    const keyOfComments = Object.keys(comments);

    const listOfComments = (keyOfComments || []).map((commentKey) => {
      const provider = _.get(providers, commentKey, '');
      const comment = _.get(comments, commentKey, '');

      return (
        <div
          className="comment-box"
          key={commentKey}
        >
          <strong>
            {provider}
          </strong>
          {provider ? ': ' : ' '}
          {comment}
        </div>
      );
    });

    const favorability = ratingUtils.calculateFavorability(
      rating1,
      rating2,
      rating3,
      rating4,
      rating5,
    );

    return (
      <div className="survey-report-item">
        <div className="card-header">
          <div className="report-box-title">
            {index + 1}
            {'. '}
            {questionText ? questionText.replace('<br>', ' ') : t('survey.type.freestyle_feedback')}
          </div>
        </div>
        <RatingsStackedBar
          rating1={rating1}
          rating2={rating2}
          rating3={rating3}
          rating4={rating4}
          rating5={rating5}
          selfAssessment={selfAssessment ? [selfAssessment] : null}
          scale={scale}
        />
        <div
          ref={(el) => { this.showComments = el; }}
          className={`collapse ${showComments ? 'show' : ''}`}
          id={`collapse${id}`}
          aria-labelledby={`heading${id}`}
          data-parent="#accordion"
        >
          <div className="card-body">
            {favorability !== null && (
              <div className="progres-question">
                <div className="row">
                  <div className="col">
                    {t('survey.label.favorability')}
                    {' '}
                    <Tooltip title={t('tooltip.favorable_question')} />
                  </div>
                  <div
                    className="col"
                    align="right"
                  >
                    {favorability}
                    %
                  </div>
                </div>
                <div
                  className="progres"
                  align="right"
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${favorability}%` }}
                    aria-valuenow={favorability}
                  />
                </div>
              </div>
            )}
            <div className="comment-section">
              <div className="comment-title">
                <strong>
                  {t('answer.label.comments')}
                </strong>
              </div>
              <hr />
              <div className="comment-listing">
                {listOfComments}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <button
          className="btn show-comments"
          type="button"
          data-toggle="collapse"
          data-target={`#collapse${id}`}
          aria-expanded="true"
          aria-controls={`collapse${id}`}
          style={{ width: '100%' }}
        >
          {open && (
            <div>
              {`${t('answer.label.hide_comments')} (${listOfComments.length})`}
              <i className="fas fa-chevron-up" />
            </div>
          )}
          {!open && (
            <div>
              {`${t('answer.label.show_comments')} (${listOfComments.length})`}
              <i className="fas fa-chevron-down" />
            </div>
          )}
        </button>
      </div>
    );
  }
}

export default withNamespaces()(SurveyReportItem);

SurveyReportItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  showComments: PropTypes.bool,
  questionText: PropTypes.string.isRequired,
  rating1: PropTypes.number,
  rating2: PropTypes.number,
  rating3: PropTypes.number,
  rating4: PropTypes.number,
  rating5: PropTypes.number,
  comments: PropTypes.shape({}),
  providers: PropTypes.shape({}),
  selfAssessment: PropTypes.shape({}),
  scale: PropTypes.objectOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number,
  })),
};

SurveyReportItem.defaultProps = {
  showComments: false,
  rating1: 0,
  rating2: 0,
  rating3: 0,
  rating4: 0,
  rating5: 0,
  comments: null,
  providers: null,
  selfAssessment: null,
  scale: null,
};
