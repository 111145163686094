const SURVEY_EXCLUDE_RULES_FETCHED = '@@survey-exclude-rule/survey-exclude-rules-fetched';
const SURVEY_EXCLUDE_RULE_FETCHED = '@@survey-exclude-rule/survey-exclude-rule-fetched';
const SURVEY_EXCLUDE_RULE_CREATED = '@@survey-admin/survey-exclude-rule-created';
const SURVEY_EXCLUDE_RULE_UPDATED = '@@survey-admin/survey-exclude-rule-updated';
const SURVEY_EXCLUDE_RULE_DELETED = '@@survey-admin/survey-exclude-rule-deleted';
const SURVEY_EXCLUDE_RULE_IMPORTED = '@@survey-admin/survey-exclude-rule-imported';
const SURVEY_EXCLUDE_RULE_GROUP_DELETED = '@@survey-admin/survey-exclude-rule-group-deleted';

export {
  SURVEY_EXCLUDE_RULES_FETCHED,
  SURVEY_EXCLUDE_RULE_FETCHED,
  SURVEY_EXCLUDE_RULE_CREATED,
  SURVEY_EXCLUDE_RULE_UPDATED,
  SURVEY_EXCLUDE_RULE_DELETED,
  SURVEY_EXCLUDE_RULE_IMPORTED,
  SURVEY_EXCLUDE_RULE_GROUP_DELETED,
};
