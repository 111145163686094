import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import RatingsStackedBar from '../RatingsStackedBar';

import ratingUtils from '../../utils/ratings';

const FeedbackOverallResults = ({
  t,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
}) => {
  const score = ratingUtils.calculateFavorability(rating1, rating2, rating3, rating4, rating5);

  return (
    <div className="feedback-overall-results report-box">
      <div className="report-box-title">
        {t('survey.label.overall_results')}
      </div>
      <RatingsStackedBar
        rating1={rating1}
        rating2={rating2}
        rating3={rating3}
        rating4={rating4}
        rating5={rating5}
      >
        <div className="row progress-bar-box">
          <div className="col" align="right">
            <div className="progres-bar-label">
              {t('survey.label.favorability')}
            </div>
            <div className="progress">
              <div
                className="progress-bar favorability"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${score}%` }}
                aria-valuenow={score}
              >
                {score}
                %
              </div>
            </div>
          </div>
        </div>
      </RatingsStackedBar>
    </div>
  );
};

export default withNamespaces()(FeedbackOverallResults);

FeedbackOverallResults.propTypes = {
  t: PropTypes.func.isRequired,
  rating1: PropTypes.number,
  rating2: PropTypes.number,
  rating3: PropTypes.number,
  rating4: PropTypes.number,
  rating5: PropTypes.number,
};

FeedbackOverallResults.defaultProps = {
  rating1: 0,
  rating2: 0,
  rating3: 0,
  rating4: 0,
  rating5: 0,
};
