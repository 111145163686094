import React from 'react';
import PropTypes from 'prop-types';

import UpdateSurvey from '../../containers/UpdateSurvey';

const UpdateSurveyPage = ({ match: { params: { id } }, history }) => (
  <main className="update-survey-page survey">
    <div className="container">
      <UpdateSurvey
        id={id}
        onSubmit={() => history.push('/#collapseFour')}
        onRedirect={() => history.push('/#collapseFour')}
      />
    </div>
  </main>
);

export default UpdateSurveyPage;

UpdateSurveyPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
