import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import SelectField from '../../../components/SelectField';
import SubmitField from '../../../components/SubmitField';
import {
  fetchSurveyExcludeRules,
  removeSurveyExcludeGroupRule,
} from '../../../redux-modules/survey-exclude-rule/actions';
import validators from '../../../utils/validators';

class AdminSurveyExcludeRuleDeleteForm extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      surveyExcludeRuleGroups: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.groupSurveyExcludeRules = this.groupSurveyExcludeRules.bind(this);
  }

  componentDidMount() {
    const { surveyExcludeRules } = this.props;

    this.mounted = true;
    this.groupSurveyExcludeRules(surveyExcludeRules);
  }

  componentDidUpdate(prevProps) {
    const { surveyExcludeRules } = this.props;

    if (prevProps.surveyExcludeRules !== surveyExcludeRules) {
      this.groupSurveyExcludeRules(surveyExcludeRules);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleSubmit(values) {
    const {
      fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction,
      removeSurveyExcludeGroupRule: removeSurveyExcludeGroupRuleAction,
      onSubmit,
      type,
    } = this.props;

    return removeSurveyExcludeGroupRuleAction(values.groupId)
      .then(() => fetchSurveyExcludeRulesAction(type).then(() => onSubmit()));
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  groupSurveyExcludeRules(surveyExcludeRules) {
    if (!this.mounted) {
      return;
    }

    const surveyExcludeRuleGroups = _.groupBy(surveyExcludeRules, 'groupId');
    this.setState({ surveyExcludeRuleGroups });
  }

  render() {
    const {
      t,
      handleSubmit,
      groupId: selectedGroupId,
    } = this.props;

    const { surveyExcludeRuleGroups } = this.state;

    const selectedSurveyExcludeRuleGroup = _.get(surveyExcludeRuleGroups, selectedGroupId, []);

    return (
      <div className="admin-create-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group mb-2">
            <Field
              name="groupId"
              label={t('survey_exclude_rule.label.group_id')}
              placeholder={t('survey_exclude_rule.placeholder.any_group_id')}
              options={Object.keys(surveyExcludeRuleGroups)
                .filter(groupId => !!groupId && groupId !== 'undefined')
                .map(groupId => ({
                  label: groupId,
                  value: groupId,
                }))}
              component={SelectField}
            />
          </div>
          {!!selectedGroupId && (
            <div className="form-group mb-2">
              {selectedSurveyExcludeRuleGroup.length === 1
                ? `One Exclude Rule of type ${t(`survey.type.${_.get(selectedSurveyExcludeRuleGroup, '0.type', '')}`)} will be removed.`
                : `${selectedSurveyExcludeRuleGroup.length} Exclude Rules of type ${t(`survey.type.${_.get(selectedSurveyExcludeRuleGroup, '0.type', '')}`)} will be removed.`
              }
            </div>
          )}
          <div className="form-group text-center mb-2 mt-4">
            <button
              className="btn standard gray"
              type="button"
              onClick={this.handleCancel}
            >
              {t('survey_exclude_rule.action.cancel')}
            </button>
            <Field
              inline
              name="submit"
              label={t('survey_exclude_rule.action.delete')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('adminSurveyExcludeRulesFilters');
const thisSelector = formValueSelector('adminSurveyExcludeRuleDeleteForm');

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.groupId)) {
    errors.groupId = t('survey_exclude_rule.error.group_id_required');
  }

  return errors;
};

const mapStateToProps = state => ({
  initialValues: {
    groupId: '',
  },
  type: selector(state, 'type'),
  groupId: thisSelector(state, 'groupId'),
  surveyExcludeRules: state.surveyExcludeRules,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyExcludeRules,
  removeSurveyExcludeGroupRule,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  validate,
  form: 'adminSurveyExcludeRuleDeleteForm',
  enableReinitialize: true,
})(AdminSurveyExcludeRuleDeleteForm)));

AdminSurveyExcludeRuleDeleteForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchSurveyExcludeRules: PropTypes.func.isRequired,
  removeSurveyExcludeGroupRule: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string,
  groupId: PropTypes.string,
  surveyExcludeRules: PropTypes.shape({}),
};

AdminSurveyExcludeRuleDeleteForm.defaultProps = {
  type: null,
  groupId: null,
  surveyExcludeRules: null,
};
