import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import SelectField from '../../../components/SelectField';
import InputField from '../../../components/InputField';
import AdminSurveyExcludeRuleForm from '../AdminSurveyExcludeRuleForm/AdminSurveyExcludeRuleForm';
import AdminSurveyExcludeRuleImportForm from '../AdminSurveyExcludeRuleImportForm';
import AdminSurveyExcludeRuleDeleteForm from '../AdminSurveyExcludeRuleDeleteForm';

class AdminSurveyExcludeRulesFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formOpen: false,
      uploadOpen: false,
      deleteOpen: false,
    };

    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleUploadOpen = this.handleUploadOpen.bind(this);
    this.handleUploadClose = this.handleUploadClose.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.openForm = this.openForm.bind(this);
  }

  componentDidUpdate({ surveyExcludeRule: surveyExcludeRulePrev }) {
    const { surveyExcludeRule } = this.props;

    if (surveyExcludeRulePrev !== surveyExcludeRule && surveyExcludeRule) {
      this.openForm();
    }
  }

  openForm() {
    this.setState({ formOpen: true });
  }

  handleFormOpen() {
    const { onFormOpen } = this.props;

    this.setState({ formOpen: true });
    onFormOpen();
  }

  handleFormClose() {
    const { onFormClose } = this.props;

    this.setState({ formOpen: false });
    onFormClose();
  }

  handleUploadOpen() {
    this.setState({ uploadOpen: true });
  }

  handleUploadClose() {
    this.setState({ uploadOpen: false });
  }

  handleDeleteOpen() {
    this.setState({ deleteOpen: true });
  }

  handleDeleteClose() {
    this.setState({ deleteOpen: false });
  }

  render() {
    const {
      t,
      handleSubmit,
      surveyExcludeRule,
      surveyExcludeRules,
    } = this.props;
    const {
      formOpen,
      uploadOpen,
      deleteOpen,
    } = this.state;

    const selectedSurveyExcludeRule = surveyExcludeRule && surveyExcludeRules
      ? surveyExcludeRules[surveyExcludeRule] : null;

    return (
      <div className="admin-filters">
        <form
          noValidate
          onSubmit={handleSubmit(() => {})}
        >
          <div className="row">
            <div className="col">
              <Field
                name="type"
                label={t('survey.label.type')}
                showErrors={false}
                placeholder={t('survey.type.all')}
                options={[
                  {
                    label: t('survey.type.all'),
                    value: '',
                  },
                  {
                    label: t('survey.type.upwards_feedback'),
                    value: 'upwards_feedback',
                  },
                  {
                    label: t('survey.type.peer_feedback'),
                    value: 'peer_feedback',
                  },
                ]}
                component={SelectField}
              />
            </div>
            <div className="col">
              <div className="form-group">
                <Field
                  name="search"
                  label={t('survey_exclude_rule.label.search')}
                  showErrors={false}
                  placeholder={t('survey_exclude_rule.placeholder.find_exclude_rule')}
                  component={InputField}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group text-right">
                <button
                  className="btn standard add-survey-exclude-rule"
                  type="button"
                  onClick={this.handleFormOpen}
                >
                  <i className="fas fa-plus" />
                  {' '}
                  {t('menu.label.new')}
                </button>
                <button
                  className="btn standard gray ml-2 add-survey-exclude-rule"
                  type="button"
                  onClick={this.handleUploadOpen}
                >
                  <i className="fas fa-file-import mr-1" />
                  {t('survey_exclude_rule.action.import')}
                </button>
                <button
                  className="btn standard gray ml-2 add-survey-exclude-rule"
                  type="button"
                  onClick={this.handleDeleteOpen}
                >
                  <i className="fas fa-trash mr-1" />
                  {t('survey_exclude_rule.action.delete')}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className={`pop-up-filter-wrapper ${formOpen ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                style={{ position: 'absolute' }}
                role="button"
                tabIndex={0}
                onClick={this.handleFormClose}
                onKeyPress={this.handleFormClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('menu.label.new')}
              </h3>
              <AdminSurveyExcludeRuleForm
                surveyExcludeRule={selectedSurveyExcludeRule}
                onSubmit={this.handleFormClose}
                onCancel={this.handleFormClose}
              />
            </div>
          </div>
        </div>
        <div className={`pop-up-filter-wrapper ${uploadOpen ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                style={{ position: 'absolute' }}
                role="button"
                tabIndex={0}
                onClick={this.handleUploadClose}
                onKeyPress={this.handleUploadClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('survey_exclude_rule.action.import')}
              </h3>
              {uploadOpen && (
                <AdminSurveyExcludeRuleImportForm
                  onSubmit={this.handleUploadClose}
                  onCancel={this.handleUploadClose}
                />
              )}
            </div>
          </div>
        </div>
        <div className={`pop-up-filter-wrapper ${deleteOpen ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                style={{ position: 'absolute' }}
                role="button"
                tabIndex={0}
                onClick={this.handleDeleteClose}
                onKeyPress={this.handleDeleteClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('survey_exclude_rule.action.delete')}
              </h3>
              {deleteOpen && (
                <AdminSurveyExcludeRuleDeleteForm
                  onSubmit={this.handleDeleteClose}
                  onCancel={this.handleDeleteClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ surveyExcludeRules }) => ({ surveyExcludeRules });

export default withNamespaces()(connect(mapStateToProps, null)(reduxForm({
  form: 'adminSurveyExcludeRulesFilters',
  destroyOnUnmount: false,
  initialValues: { type: null },
})(AdminSurveyExcludeRulesFilters)));

AdminSurveyExcludeRulesFilters.propTypes = {
  surveyExcludeRule: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  surveyExcludeRules: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onFormOpen: PropTypes.func,
  onFormClose: PropTypes.func,
};

AdminSurveyExcludeRulesFilters.defaultProps = {
  surveyExcludeRule: null,
  surveyExcludeRules: null,
  onFormOpen: () => {},
  onFormClose: () => {},
};
