import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import ratingUtils from '../../utils/ratings';

import selfIcon from '../../assets/images/self-icon.svg';

const RatingsStackedBar = ({
  t,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
  selfAssessment,
  selfScore,
  scale,
  children,
}) => {
  const ratings = ratingUtils.calculateRatings(rating1, rating2, rating3, rating4, rating5);
  const hasSelfAssessment = selfAssessment && selfAssessment.length > 0;
  const isOverall = selfAssessment && selfAssessment.length > 1;

  if (!ratings) {
    return null;
  }

  return (
    <div className="overall-results">
      <div className="stacked-bar">
        <span
          className="bar-1"
          style={{ width: `${ratings[1].value}%` }}
          data-toggle="tooltip"
          data-html="true"
          title={t('tooltip.bar').replace(
            '{number}',
            `<strong>${rating1} '${t(_.get(scale, '1.text', 'answer.label.rating_1'))}'</strong>`,
          )}
        >
          {rating1}
        </span>
        <span
          className="bar-2"
          style={{ width: `${ratings[2].value}%` }}
          data-toggle="tooltip"
          data-html="true"
          title={t('tooltip.bar').replace(
            '{number}',
            `<strong>${rating2} '${t(_.get(scale, '2.text', 'answer.label.rating_2'))}'</strong>`,
          )}
        >
          {rating2}
        </span>
        <span
          className="bar-3"
          style={{ width: `${ratings[3].value}%` }}
          data-toggle="tooltip"
          data-html="true"
          title={t('tooltip.bar').replace(
            '{number}',
            `<strong>${rating3} '${t(_.get(scale, '3.text', 'answer.label.rating_3'))}'</strong>`,
          )}
        >
          {rating3}
        </span>
        <span
          className="bar-4"
          style={{ width: `${ratings[4].value}%` }}
          data-toggle="tooltip"
          data-html="true"
          title={t('tooltip.bar').replace(
            '{number}',
            `<strong>${rating4} '${t(_.get(scale, '4.text', 'answer.label.rating_4'))}'</strong>`,
          )}
        >
          {rating4}
        </span>
        <span
          className="bar-5"
          style={{ width: `${ratings[5].value}%` }}
          data-toggle="tooltip"
          data-html="true"
          title={t('tooltip.bar').replace(
            '{number}',
            `<strong>${rating5} '${t(_.get(scale, '5.text', 'answer.label.rating_5'))}'</strong>`,
          )}
        >
          {rating5}
        </span>
      </div>
      {hasSelfAssessment && (
        <div
          className="self-icon"
          style={{
            left: isOverall
              ? `${Math.min(selfScore, 100)}%`
              : `${Math.min((selfAssessment[0].rating - 1) * 25, 100)}%`,
          }}
          data-toggle="tooltip"
          data-html="true"
          title={isOverall
            ? `<strong>${t('tooltip.self_overall')}:</strong> ${Math.floor(Math.min(selfScore, 100))}%`
            : `<strong>${t('tooltip.self_question')}:</strong><br />${t(_.get(scale, `${selfAssessment[0].rating}.text`, `answer.label.rating_${selfAssessment[0].rating}`))}`
          }
        >
          <img
            src={selfIcon}
            alt="self icon"
          />
          <div className="self-text">
            {t('survey.label.self')}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default withNamespaces()(RatingsStackedBar);

RatingsStackedBar.propTypes = {
  t: PropTypes.func.isRequired,
  rating1: PropTypes.number,
  rating2: PropTypes.number,
  rating3: PropTypes.number,
  rating4: PropTypes.number,
  rating5: PropTypes.number,
  selfAssessment: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
  })),
  selfScore: PropTypes.number,
  scale: PropTypes.objectOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number,
  })),
  children: PropTypes.element,
};

RatingsStackedBar.defaultProps = {
  rating1: 0,
  rating2: 0,
  rating3: 0,
  rating4: 0,
  rating5: 0,
  selfAssessment: null,
  selfScore: 0,
  scale: null,
  children: null,
};
