import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import Table from '../../../components/Table';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  sendProgressReport,
  sendQualitativeReport,
  sendFullExportReport,
  sendSurveyReport,
  sendSurveyInitialReport,
  sendTaskReport,
  sendScoreReport,
  sendParticipationRateReport,
  sendCchExportReport,
} from '../../../redux-modules/admin-report/actions';
import { fetchSurveysAdmin, removeSurveysAdmin } from '../../../redux-modules/survey-admin/actions';

import './admin-survey-table.css';

class AdminSurveyTable extends Component {
  constructor(props) {
    super(props);

    this.state = ({
      open: false,
      selectedSurvey: 0,
    });

    this.handleClick = this.handleClick.bind(this);
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSendProgressReport = this.handleSendProgressReport.bind(this);
    this.handleSendQualitativeReport = this.handleSendQualitativeReport.bind(this);
    this.handleSendFullExportReport = this.handleSendFullExportReport.bind(this);
    this.handleSendSurveyReport = this.handleSendSurveyReport.bind(this);
    this.handleSendSurveyInitialReport = this.handleSendSurveyInitialReport.bind(this);
    this.handleSendTaskReport = this.handleSendTaskReport.bind(this);
    this.handleSendScoreReport = this.handleSendScoreReport.bind(this);
    this.handleSendParticipationRateReport = this.handleSendParticipationRateReport.bind(this);
    this.handleSendCchExportReport = this.handleSendCchExportReport.bind(this);
  }

  componentDidMount() {
    const {
      token,
      fetchSurveysAdmin: fetchSurveysAdminAction,
      filterAdminSurvey: { type, year },
    } = this.props;

    fetchSurveysAdminAction(token, type, year);
  }

  handleClick(id) {
    const { onClick } = this.props;
    onClick(id);
  }

  handleYes() {
    const {
      token,
      filterAdminSurvey: { type },
      removeSurveysAdmin: removeSurveysAdminAction,
      fetchSurveysAdmin: fetchSurveysAdminAction,
    } = this.props;
    const { selectedSurvey } = this.state;

    removeSurveysAdminAction(token, selectedSurvey).then(
      () => {
        fetchSurveysAdminAction(token, type);
        this.setState({ open: false, selectedSurvey: 0 });
      },
      () => {},
    );
  }

  handleNo() {
    this.setState({ open: false });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSendProgressReport(surveyType, year, quarter) {
    const {
      token,
      sendProgressReport: sendProgressReportAction,
    } = this.props;

    sendProgressReportAction(surveyType, year, quarter, token);
  }

  handleSendQualitativeReport(surveyType, year, quarter) {
    const {
      token,
      sendQualitativeReport: sendQualitativeReportAction,
    } = this.props;

    sendQualitativeReportAction(surveyType, year, quarter, token);
  }

  handleSendFullExportReport(surveyType, year, quarter) {
    const {
      token,
      sendFullExportReport: sendFullExportReportAction,
    } = this.props;

    sendFullExportReportAction(surveyType, year, quarter, token);
  }

  handleSendSurveyReport(surveyType, year, quarter) {
    const {
      token,
      sendSurveyReport: sendSurveyReportAction,
    } = this.props;

    sendSurveyReportAction(surveyType, year, quarter, token);
  }

  handleSendSurveyInitialReport(surveyType, year, quarter) {
    const {
      token,
      sendSurveyInitialReport: sendSurveyInitialReportAction,
    } = this.props;

    sendSurveyInitialReportAction(surveyType, year, quarter, token);
  }

  handleSendTaskReport(surveyType, year, quarter) {
    const {
      token,
      sendTaskReport: sendTaskReportAction,
    } = this.props;

    sendTaskReportAction(surveyType, year, quarter, token);
  }

  handleSendScoreReport(surveyType, year, quarter) {
    const {
      token,
      sendScoreReport: sendScoreReportAction,
    } = this.props;

    sendScoreReportAction(surveyType, year, quarter, token);
  }

  handleSendParticipationRateReport(surveyType, year, quarter) {
    const {
      token,
      sendParticipationRateReport: sendParticipationRateReportAction,
    } = this.props;

    sendParticipationRateReportAction(surveyType, year, quarter, token);
  }

  handleSendCchExportReport(surveyType, year, quarter) {
    const {
      token,
      sendCchExportReport: sendCchExportReportAction,
    } = this.props;

    sendCchExportReportAction(surveyType, year, quarter, token);
  }

  render() {
    const {
      t,
      surveysAdmin,
      filterAdminSurvey: {
        unit,
        segment,
        status,
      },
    } = this.props;
    const { open } = this.state;

    const data = (_.values(surveysAdmin) || [])
      .filter((survey) => {
        if (unit && unit !== survey.businessUnit) {
          return false;
        }
        if (segment && segment !== survey.workforceSegment) {
          return false;
        }
        if (status === 'complete' && survey.isEditable) {
          return false;
        }
        if (status === 'active' && !survey.isEditable) {
          return false;
        }

        return true;
      })
      .map(survey => ({
        ...survey,
        ...{
          type: survey.type.includes('customer')
            ? t('survey.type.customer_feedback')
            : t(`survey.type.${survey.type}`),
          rawType: survey.type,
          cycle: `${survey.year} Q${survey.quarter}`,
          businessUnit: survey.businessUnit ? survey.businessUnit : '-',
          workforceSegment: survey.workforceSegment ? survey.workforceSegment : '-',
          endDate: moment.utc(survey.endDate).format(t('date_format')),
          launchDate: moment.utc(survey.launchDate).format(t('date_format')),
        },
      }));

    const columns = [
      {
        dataField: 'cycle',
        text: t('survey.label.cycle'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const sortA = `${rowA.year} Q${rowA.quarter}`;
          const sortB = `${rowB.year} Q${rowB.quarter}`;

          if (sortA < sortB) {
            return order === 'asc' ? -1 : 1;
          }
          if (sortA > sortB) {
            return order === 'asc' ? 1 : -1;
          }

          return 0;
        },
      },
      {
        dataField: 'type',
        text: t('survey.label.type'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'businessUnit',
        text: t('user.label.business_unit'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'workforceSegment',
        text: t('survey.label.workforce_segment'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
      },
      {
        dataField: 'launchDate',
        text: t('survey.label.launch_date'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const launchDateA = moment(rowA.launchDate, t('date_format'));
          const launchDateB = moment(rowB.launchDate, t('date_format'));

          if (order === 'asc') {
            if (launchDateA.isAfter(launchDateB)) {
              return 1;
            }
            if (launchDateA.isBefore(launchDateB)) {
              return -1;
            }

            return 0;
          }

          if (launchDateA.isBefore(launchDateB)) {
            return 1;
          }
          if (launchDateA.isAfter(launchDateB)) {
            return -1;
          }

          return 0;
        },
      },
      {
        dataField: 'endDate',
        text: t('survey.label.end_date'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const endDateA = moment(rowA.endDate, t('date_format'));
          const endDateB = moment(rowB.endDate, t('date_format'));

          if (order === 'asc') {
            if (endDateA.isAfter(endDateB)) {
              return 1;
            }
            if (endDateA.isBefore(endDateB)) {
              return -1;
            }

            return 0;
          }

          if (endDateA.isBefore(endDateB)) {
            return 1;
          }
          if (endDateA.isAfter(endDateB)) {
            return -1;
          }

          return 0;
        },
      },
      {
        dataField: 'providers',
        text: t('survey.label.progress'),
        sort: true,
        headerClasses: () => ['sort'],
        headerSortingClasses: (column, sortOrder) => [`${sortOrder}`],
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          const countA = rowA.progressCount;
          const totalA = rowA.progressTotal;
          const percentageA = totalA === 0 ? 0 : Math.floor(100 * countA / totalA);

          const countB = rowB.progressCount;
          const totalB = rowB.progressTotal;
          const percentageB = totalB === 0 ? 0 : Math.floor(100 * countB / totalB);

          if (order === 'asc') {
            return percentageA - percentageB;
          }
          return percentageB - percentageA;
        },
        formatter: (cellContent, row) => {
          const count = row.progressCount;
          const total = row.progressTotal;
          const percentage = total === 0 ? 0 : Math.floor(100 * count / total);

          return (
            <div>
              <div className="progress-in-numbers text-center">
                {count}
                /
                {total}
              </div>
              <div className="progress">
                <div
                  className="progress-bar favorability"
                  role="progressbar"
                  style={{ width: `${percentage}%` }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {percentage}
                  %
                </div>
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'delete',
        isDummyField: true,
        text: t('survey.label.actions'),
        formatter: (cellContent, row) => (
          <div>
            <button
              className={`btn standard ${row.isEditable ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleClick(row.id);
              }}
            >
              {t('menu.link.edit')}
            </button>
            <button
              className={`btn standard gray ${row.isDeletable ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.setState({ open: true, selectedSurvey: row.id });
              }}
            >
              {t('survey.delete_dialog.button')}
            </button>
            <button
              className="btn standard cyan"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendSurveyReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.survey_report')}
            </button>
            <button
              className="btn standard cyan"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendSurveyInitialReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.survey_initial_report')}
            </button>
            <button
              className={`btn standard cyan ${!row.isDeletable ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendTaskReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.task_report')}
            </button>
            <button
              className={`btn standard cyan ${!row.isEditable && row.rawType !== 'customer_feedback' ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendProgressReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.progress_report')}
            </button>
            <button
              className={`btn standard cyan ${!row.isEditable && row.rawType !== 'customer_feedback' ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendQualitativeReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.qualitative_report')}
            </button>
            <button
              className={`btn standard cyan ${!row.isEditable && row.rawType !== 'customer_feedback' ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendScoreReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.score_report')}
            </button>
            <button
              className={`btn standard cyan ${!row.isEditable && row.rawType !== 'customer_feedback' ? '' : 'd-none'}`}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendFullExportReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.full_export_report')}
            </button>
            <button
              className="btn standard cyan"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendParticipationRateReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.participation_rate_report')}
            </button>
            <button
              className="btn standard cyan"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                this.handleSendCchExportReport(row.rawType, row.year, row.quarter);
              }}
            >
              {t('survey.action.cch_export_report')}
            </button>
          </div>
        ),
      },
    ];

    if (data.length === 0) {
      return (
        <div
          style={{ padding: '100px' }}
          className="content-box accordion"
        >
          <div className="box-list">
            <p align="center">
              <strong>
                {t('survey.label.no_survey')}
              </strong>
            </p>
            <p align="center">
              {t('survey.label.no_survey_desc')}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="admin-survey-table table-surveys">
        <Table
          data={data}
          columns={columns}
          defaultSorted={[{
            dataField: 'cycle',
            order: 'desc',
          }]}
        />
        <ConfirmDialog
          title={t('survey.delete_dialog.title')}
          body={t('survey.delete_dialog.body')}
          open={open}
          onClose={this.handleClose}
          onYes={this.handleYes}
          onNo={this.handleNo}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  surveysAdmin,
  filterAdminSurvey,
}) => ({
  token,
  surveysAdmin,
  filterAdminSurvey,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveysAdmin,
  removeSurveysAdmin,
  sendProgressReport,
  sendQualitativeReport,
  sendFullExportReport,
  sendSurveyReport,
  sendSurveyInitialReport,
  sendTaskReport,
  sendScoreReport,
  sendParticipationRateReport,
  sendCchExportReport,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AdminSurveyTable));

AdminSurveyTable.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  surveysAdmin: PropTypes.shape({}),
  filterAdminSurvey: PropTypes.shape({
    type: PropTypes.string,
  }),
  fetchSurveysAdmin: PropTypes.func.isRequired,
  removeSurveysAdmin: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sendProgressReport: PropTypes.func.isRequired,
  sendQualitativeReport: PropTypes.func.isRequired,
  sendFullExportReport: PropTypes.func.isRequired,
  sendSurveyReport: PropTypes.func.isRequired,
  sendSurveyInitialReport: PropTypes.func.isRequired,
  sendTaskReport: PropTypes.func.isRequired,
  sendScoreReport: PropTypes.func.isRequired,
  sendParticipationRateReport: PropTypes.func.isRequired,
  sendCchExportReport: PropTypes.func.isRequired,
};

AdminSurveyTable.defaultProps = {
  surveysAdmin: null,
  filterAdminSurvey: null,
};
