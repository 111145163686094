import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import SurveyStatistic from '../../components/SurveyStatistic';
import {
  fetchSurveyStatisticTeam,
  fetchSurveyStatisticPartner,
} from '../../redux-modules/survey-statistic/actions';

import subareas from '../../utils/subareas';

class SurveyStatisticTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compareWith: 'business-unit',
      user: null,
      partner: null,
      depth: 1,
      selectedChart: 'all',
      selectedValue: '',
    };

    this.handleChartChange = this.handleChartChange.bind(this);
    this.handleCompareWithChange = this.handleCompareWithChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount() {
    const {
      token,
      userProfile,
      fetchSurveyStatisticTeam: fetchSurveyStatisticTeamAction,
      fetchSurveyStatisticPartner: fetchSurveyStatisticPartnerAction,
    } = this.props;
    const { compareWith, user, depth } = this.state;

    const isPartner = userProfile.partnership && !userProfile.isManager;

    if (isPartner) {
      fetchSurveyStatisticPartnerAction(token, subareas.getAllKeys());
    } else {
      fetchSurveyStatisticTeamAction(token, compareWith, depth, user);
    }
  }

  handleCompareWithChange(compareWith) {
    const {
      token,
      fetchSurveyStatisticTeam: fetchSurveyStatisticTeamAction,
      fetchSurveyStatisticPartner: fetchSurveyStatisticPartnerAction,
    } = this.props;
    const { user, depth } = this.state;

    const partner = Number.isNaN(Number(user)) ? user : null;

    this.setState({ compareWith });
    if (partner) {
      const subareasIds = partner === 'all_bu' ? subareas.getAllKeys() : subareas.getIds(partner);
      fetchSurveyStatisticPartnerAction(token, subareasIds);
    } else {
      fetchSurveyStatisticTeamAction(token, compareWith, depth, user);
    }
  }

  handleUserChange(user) {
    const {
      token,
      fetchSurveyStatisticTeam: fetchSurveyStatisticTeamAction,
      fetchSurveyStatisticPartner: fetchSurveyStatisticPartnerAction,
    } = this.props;
    const { compareWith } = this.state;

    const depth = user === -1 ? 10 : 1;
    const u = Number(user) <= 0 ? null : user;
    const partner = Number.isNaN(Number(user)) ? user : null;

    if (!u) {
      this.setState({ compareWith: 'business-unit' });
    }

    this.setState({ depth, user: u, partner });

    if (partner) {
      const subareasIds = partner === 'all_bu' ? subareas.getAllKeys() : subareas.getIds(partner);
      fetchSurveyStatisticPartnerAction(token, subareasIds);
    } else {
      fetchSurveyStatisticTeamAction(
        token,
        u ? compareWith : 'business-unit',
        depth,
        u,
      );
    }
  }

  handleChartChange(chart, value) {
    const { selectedChart, selectedValue } = this.state;

    if (selectedChart !== chart) {
      this.setState({ selectedChart: chart });
    }
    if (selectedValue !== value) {
      this.setState({ selectedValue: value });
    }
  }

  render() {
    const {
      compareWith,
      selectedChart,
      selectedValue,
      user,
      depth,
      partner,
    } = this.state;
    const { t, teamStatistic, userProfile } = this.props;

    if (!teamStatistic) {
      return <div />;
    }

    const users = _.get(userProfile.team, 'users', []);
    let userOptions = users.map(u => ({
      value: u.id,
      label: u.name,
    }));
    if (users.length > 0) {
      userOptions.push({
        value: -1,
        label: t('user.label.organization_below_me'),
      });
    }

    const HRBP = [
      {
        value: 'all_bu',
        label: t('user.label.hrbp_bu'),
      },
      {
        value: 'Commercial',
        label: t('user.label.hrbp_commercial'),
      },
      {
        value: 'Supply Chain',
        label: t('user.label.hrbp_supply_chain'),
      },
      {
        value: 'Finance',
        label: t('user.label.hrbp_finance'),
      },
      {
        value: 'HR',
        label: t('user.label.hrbp_hr'),
      },
      {
        value: 'Legal',
        label: t('user.label.hrbp_legal'),
      },
      {
        value: 'PA&C',
        label: t('user.label.hrbp_pac'),
      },
      {
        value: 'BSS',
        label: t('user.label.hrbp_bss'),
      },
    ];

    if (userProfile.partnership) {
      userOptions = userOptions.concat(HRBP);
    }

    let legend = {
      results: depth > 1 ? t('user.label.organization_below_me') : t('survey.placeholder.any_user'),
      benchmark: t('user.label.business_unit'),
      total: selectedChart === 'participation' && selectedValue ? t('user.label.total') : null,
    };
    if (user) {
      const selectedUser = _.find(users, ['id', user]);
      legend = {
        results: selectedUser ? selectedUser.name : '',
        benchmark: compareWith === 'business-unit'
          ? t('user.label.business_unit')
          : t('user.label.workforce_segment_similar'),
        total: selectedChart === 'participation' && selectedValue ? t('user.label.total') : null,
      };
    }
    if (partner) {
      const selectedPartner = _.find(HRBP, ['value', partner]);
      legend = {
        results: selectedPartner ? selectedPartner.label : '',
        benchmark: compareWith === 'business-unit'
          ? t('user.label.business_unit')
          : t('user.label.workforce_segment_similar'),
        total: selectedChart === 'participation' && selectedValue ? t('user.label.total') : null,
      };
    }

    return (
      <div className="survey-statistic-team">
        <SurveyStatistic
          user={teamStatistic.user}
          compare={teamStatistic.compare}
          isManager
          isPartner={userProfile.partnership && !userProfile.isManager}
          userOptions={userOptions}
          legend={legend}
          onChartChange={this.handleChartChange}
          onCompareChange={this.handleCompareWithChange}
          onUserChange={this.handleUserChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  teamStatistic,
  userProfile,
}) => ({ token, teamStatistic, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyStatisticTeam,
  fetchSurveyStatisticPartner,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(SurveyStatisticTeam));

SurveyStatisticTeam.propTypes = {
  t: PropTypes.func.isRequired,
  fetchSurveyStatisticTeam: PropTypes.func.isRequired,
  fetchSurveyStatisticPartner: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  teamStatistic: PropTypes.shape({}),
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
    team: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      name: PropTypes.string,
      language: PropTypes.string,
    }),
  }),
};

SurveyStatisticTeam.defaultProps = {
  teamStatistic: null,
  userProfile: null,
};
