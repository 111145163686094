import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import { Line, Bar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

import SelectField from '../SelectField';

class SurveyStatistic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterType: props.isManager ? 'upwards_feedback' : 'peer_feedback',
      filterCategory: '',
      compare: '',
      filterTimeframe: '',
      chartType: 'progress_in_time',
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleCompareChange = this.handleCompareChange.bind(this);
    this.handleTimeframeChange = this.handleTimeframeChange.bind(this);
    this.handleChartChange = this.handleChartChange.bind(this);
    this.renderScoreBar = this.renderScoreBar.bind(this);
    this.renderParticipationBar = this.renderParticipationBar.bind(this);
  }

  componentDidMount() {
    const { initialize, isManager, isPartner } = this.props;

    initialize({
      type: isManager ? 'upwards_feedback' : 'peer_feedback',
      category: '',
      user: isPartner ? 'all_bu' : null,
      compare: 'business-unit',
      timeframe: '',
      participation: 'progress_in_time',
    });
  }

  handleTypeChange(filterType) {
    const { change, onChartChange, onCompareChange } = this.props;
    const { filterCategory, compare, chartType } = this.state;

    this.setState({ filterType });

    if (filterType === 'project_feedback' && filterCategory) {
      this.setState({ filterCategory: '' });
      change('category', '');
    }
    if (filterType !== 'upwards_feedback' && compare === 'self-assessment') {
      this.setState({ compare: 'business-unit' });
      change('compare', 'business-unit');
      onCompareChange('business-unit');
    }

    onChartChange(chartType, filterType);
  }

  handleCategoryChange(filterCategory) {
    this.setState({ filterCategory });
  }

  handleUserChange(selectedUser) {
    const { onUserChange } = this.props;

    onUserChange(selectedUser);
  }

  handleCompareChange(compare) {
    const { onCompareChange } = this.props;

    this.setState({ compare });

    onCompareChange(compare);
  }

  handleTimeframeChange(filterTimeframe) {
    this.setState({ filterTimeframe });
  }

  handleChartChange(chartType) {
    const { change, onChartChange, onCompareChange } = this.props;
    const { filterType, filterCategory, compare } = this.state;

    this.setState({ chartType });

    if (chartType === 'participation' && filterCategory) {
      this.setState({ filterCategory: '' });
      change('category', '');
    }
    if (chartType !== 'participation' && compare === 'self-assessment') {
      this.setState({ compare: 'business-unit' });
      change('compare', 'business-unit');
      onCompareChange('business-unit');
    }

    onChartChange(chartType, filterType);
  }

  renderScoreBar(user, compare) {
    const { t } = this.props;

    const a = [];
    const b = [];
    const x = [];

    user.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in a)) {
        a[key] = [];
      }
      a[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    compare.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in b)) {
        b[key] = [];
      }
      b[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    const data1 = x.sort().map(key => (key in a
      ? Math.floor(a[key]
        .map(u => u.displayScore)
        .reduce((first, second) => first + second) * 100 / a[key].length)
      : 0
    ));
    const data2 = x.sort().map(key => (key in b
      ? Math.floor(b[key]
        .map(u => u.displayScore)
        .reduce((first, second) => first + second) * 100 / b[key].length)
      : 0
    ));
    const dataFirst = {
      label: t('survey.label.your_results'),
      backgroundColor: 'transparent',
      borderColor: 'rgb(228, 33, 44)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(228, 33, 44)',
      pointBorderColor: 'rgb(228, 33, 44)',
      pointHoverRadius: 4,
      data: data1,
    };
    const dataSecond = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'transparent',
      borderColor: 'rgb(0, 0, 0)',
      fill: false,
      radius: 4,
      lineTension: 0,
      pointRadius: 4,
      pointBorderWidth: 3,
      pointBackgroundColor: 'rgb(0, 0, 0)',
      pointBorderColor: 'rgb(0, 0, 0)',
      pointHoverRadius: 4,
      data: data2,
    };
    const speedData = {
      labels: x.sort(),
      datasets: [dataFirst, dataSecond],
    };
    const chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              maxTicksLimit: 100,
              min: 0,
              max: 100,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: item => `${item.yLabel}%`,
        },
      },
    };

    return (
      <Line
        data={speedData}
        options={chartOptions}
      />
    );
  }

  renderParticipationBar(user, compare) {
    const { t } = this.props;
    const { filterType } = this.state;

    const a = [];
    const b = [];
    const x = [];

    user.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in a)) {
        a[key] = [];
      }
      a[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    compare.forEach((u) => {
      const key = `${u.year} Q${u.quarter}`;
      if (!(key in b)) {
        b[key] = [];
      }
      b[key].push(u);
      if (!x.includes(key)) {
        x.push(key);
      }
    });

    const data1 = x.sort().map(key => (key in a
      ? Math.round(a[key]
        .map(u => (u.progressCount))
        .reduce((first, second) => first + second) / a[key].length)
      : 0
    ));
    const data1Total = x.sort().map(key => (key in a
      ? Math.round(a[key]
        .map(u => (u.progressTotal))
        .reduce((first, second) => first + second) / a[key].length)
      : 0
    ));
    const data2 = x.sort().map(key => (key in b
      ? Math.round(b[key]
        .map(u => (u.progressCount))
        .reduce((first, second) => first + second) / b[key].length)
      : 0
    ));
    const data2Total = x.sort().map(key => (key in b
      ? Math.round(b[key]
        .map(u => (u.progressTotal))
        .reduce((first, second) => first + second) / b[key].length)
      : 0
    ));
    const data1TotalSub = data1.map((item, index) => (data1Total[index] - item));
    const data2TotalSub = data2.map((item, index) => (data2Total[index] - item));
    const dataFirst = {
      label: t('survey.label.your_results'),
      backgroundColor: 'rgb(228, 33, 44)',
      data: data1,
      stack: `${filterType}1`,
    };
    const dataFirstTotal = {
      label: t('survey.label.your_results'),
      backgroundColor: 'rgb(167, 169, 172)',
      data: data1TotalSub,
      stack: `${filterType}1`,
    };
    const dataSecond = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'rgb(0, 0, 0)',
      data: data2,
      stack: `${filterType}2`,
    };
    const dataSecondTotal = {
      label: t('survey.label.benchmark'),
      backgroundColor: 'rgb(167, 169, 172)',
      data: data2TotalSub,
      stack: `${filterType}2`,
    };
    const speedData = {
      labels: x.sort(),
      datasets: [dataFirst, dataFirstTotal, dataSecond, dataSecondTotal],
    };
    const chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
              maxTicksLimit: 100,
              min: 0,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: (item, dataset) => {
            const isBenchmark = item.datasetIndex > 1;
            const hide = (item.datasetIndex % 2) > 0;
            const current = isBenchmark
              ? _.get(dataset, `datasets.2.data.${item.index}`, 0)
              : _.get(dataset, `datasets.0.data.${item.index}`, 0);
            const total = current + (isBenchmark
              ? _.get(dataset, `datasets.3.data.${item.index}`, 0)
              : _.get(dataset, `datasets.1.data.${item.index}`, 0));
            const percentage = total > 0 ? Math.floor((current / total) * 100) : 0;

            return hide ? null : `${percentage}% (${current}/${total})`;
          },
        },
      },
    };

    return (
      <Bar
        data={speedData}
        options={chartOptions}
        redraw
      />
    );
  }

  render() {
    const {
      t,
      user,
      compare,
      isManager,
      isPartner,
      userOptions,
      legend,
      handleSubmit,
    } = this.props;

    const {
      filterType,
      filterCategory,
      filterTimeframe,
      chartType,
    } = this.state;

    const currentYear = moment().year();
    const currentQuarter = moment().quarter();

    const userStatistic = user
      .filter((u) => {
        if (filterType && !u.type.includes(filterType)) {
          return false;
        }

        if (filterTimeframe && filterTimeframe === 'this_quarter' && (u.quarter !== currentQuarter || u.year !== currentYear)) {
          return false;
        }

        if (filterTimeframe && filterTimeframe === 'this_year' && u.year !== currentYear) {
          return false;
        }

        return true;
      })
      .map((c) => {
        const displayScore = filterCategory ? _.get(c, `categoryScore.${filterCategory}.score`, 0) : c.score;

        return Object.assign(c, { displayScore });
      });

    const compareStatistic = compare
      .filter((c) => {
        if (filterType && !c.type.includes(filterType)) {
          return false;
        }

        if (filterTimeframe && filterTimeframe === 'this_quarter' && (c.quarter !== currentQuarter || c.year !== currentYear)) {
          return false;
        }

        if (filterTimeframe && filterTimeframe === 'this_year' && c.year !== currentYear) {
          return false;
        }

        return true;
      })
      .map((c) => {
        const displayScore = filterCategory ? _.get(c, `categoryScore.${filterCategory}.score`, 0) : c.score;

        return Object.assign(c, { displayScore });
      });

    const typeOptions = [
      (isManager
        ? {
          label: t('survey.type.upwards_feedback'),
          value: 'upwards_feedback',
        }
        : null
      ),
      {
        label: t('survey.type.peer_feedback'),
        value: 'peer_feedback',
      },
    ].filter(Boolean);

    const showCategories = (filterType === 'upwards_feedback' || filterType === 'peer_feedback')
      && chartType === 'progress_in_time';
    const categoryOptions = showCategories
      ? [
        {
          label: t('survey.label.question_category_1'),
          value: 'question_category_1',
        },
        {
          label: t('survey.label.question_category_2'),
          value: 'question_category_2',
        },
        {
          label: t('survey.label.question_category_3'),
          value: 'question_category_3',
        },
      ].filter(Boolean)
      : [];

    const compareOptions = [
      {
        label: t('user.label.business_unit'),
        value: 'business-unit',
      },
      {
        label: t('user.label.workforce_segment_similar'),
        value: 'workforce-segment',
      },
      (
        (filterType === 'upwards_feedback' && chartType === 'progress_in_time')
          ? {
            label: t('survey.label.self_assessment'),
            value: 'self-assessment',
          }
          : null
      ),
    ].filter(Boolean);

    const timeframeOptions = [
      {
        label: t('survey.label.this_quarter'),
        value: 'this_quarter',
      },
      {
        label: t('survey.label.this_year'),
        value: 'this_year',
      },
    ].filter(Boolean);

    const chartTypeOptions = [
      {
        label: t('survey.label.results'),
        value: 'progress_in_time',
      },
      {
        label: t('survey.label.participation_rate'),
        value: 'participation',
      },
    ].filter(Boolean);

    return (
      <div className="compare-filters">
        <form
          noValidate
          onSubmit={handleSubmit(() => {})}
        >
          <div className="row justify-content-start">
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.type')}:`}
                </strong>
              </div>
              <Field
                component={SelectField}
                name="type"
                options={typeOptions}
                placeholder={t('survey.placeholder.any_type')}
                showErrors={false}
                onValueChange={value => this.handleTypeChange(value)}
              />
            </div>
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.category')}:`}
                </strong>
              </div>
              <Field
                component={SelectField}
                name="category"
                options={categoryOptions}
                placeholder={t('survey.placeholder.any_category')}
                showErrors={false}
                hasEmpty
                onValueChange={value => this.handleCategoryChange(value)}
              />
            </div>
            {userOptions && (
              <div className="col">
                <div className="compare-filter-title">
                  <strong>
                    {`${t('survey.label.feedback_receiver')}:`}
                  </strong>
                </div>
                <Field
                  component={SelectField}
                  name="user"
                  options={userOptions}
                  placeholder={t('survey.placeholder.any_user')}
                  showErrors={false}
                  hasEmpty={!isPartner}
                  onValueChange={value => this.handleUserChange(value)}
                />
              </div>
            )}
          </div>
          <div>
            <div className="col">
              <div className="survey-statistic mt-3">
                {chartType === 'participation' && (this.renderParticipationBar(userStatistic, compareStatistic))}
                {chartType === 'progress_in_time' && (this.renderScoreBar(userStatistic, compareStatistic))}
                <div className="legend">
                  <ul>
                    <li className="your-results-legend">
                      {legend.results}
                    </li>
                    <li className="benchmark-legend">
                      {legend.benchmark}
                    </li>
                    {legend && legend.total && (
                      <li className="total-legend">
                        {legend.total}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.compare')}:`}
                </strong>
              </div>
              <Field
                component={SelectField}
                name="compare"
                options={compareOptions}
                showErrors={false}
                onValueChange={value => this.handleCompareChange(value)}
              />
            </div>
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.timeframe')}:`}
                </strong>
              </div>
              <Field
                component={SelectField}
                name="timeframe"
                options={timeframeOptions}
                placeholder={t('survey.placeholder.all_time')}
                showErrors={false}
                hasEmpty
                onValueChange={value => this.handleTimeframeChange(value)}
              />
            </div>
            <div className="col">
              <div className="compare-filter-title">
                <strong>
                  {`${t('survey.label.results_participation')}:`}
                </strong>
              </div>
              <Field
                component={SelectField}
                name="participation"
                options={chartTypeOptions}
                showErrors={false}
                onValueChange={value => this.handleChartChange(value)}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withNamespaces()(reduxForm({ form: 'surveyStatisticFilters' })(SurveyStatistic));

SurveyStatistic.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  compare: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isManager: PropTypes.bool,
  isPartner: PropTypes.bool,
  userOptions: PropTypes.arrayOf(PropTypes.shape({})),
  legend: PropTypes.shape({
    results: PropTypes.string,
    benchmark: PropTypes.string,
  }).isRequired,
  onChartChange: PropTypes.func.isRequired,
  onCompareChange: PropTypes.func.isRequired,
  onUserChange: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

SurveyStatistic.defaultProps = {
  isManager: true,
  isPartner: false,
  userOptions: null,
  onUserChange: null,
};
