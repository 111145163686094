import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import token from './auth/reducers';
import userProfile from './me/reducers';
import activateUserStatus from './activate-user/reducers';
import registeredUser from './register-user/reducers';
import notifications from './notification/reducers';
import { feedback, feedbackList } from './feedback/reducers';
import feedbackExternal from './feedback-external/reducers';
import {
  surveys,
  lastSurvey,
  closedSurveys,
  teamSurveys,
  totalSurveys,
  totalTeamSurveys,
} from './survey/reducers';
import surveysAdmin from './survey-admin/reducers';
import { users, lastSyncDate } from './user/reducers';
import { surveyReports, teamSurveyReports, surveyReport } from './survey-report/reducers';
import { statistic, teamStatistic } from './survey-statistic/reducers';
import filterAdminSurvey from './filter-admin-survey/reducers';
import filterUserSurvey from './filter-survey/reducers';
import filterUserFeedback from './filter-feedback/reducers';
import callToActions from './call-to-action/reducers';
import workforceSegment from './workforce-segment/reducers';
import businessUnit from './business-unit/reducers';
import customerExternal from './customer-external/reducers';
import surveyExcludeRules from './survey-exclude-rule/reducers';
import transferHistory from './transfer-history/reducers';

const rootReducer = combineReducers({
  form: formReducer,
  token,
  userProfile,
  activateUserStatus,
  registeredUser,
  notifications,
  feedback,
  feedbackExternal,
  feedbackList,
  surveys,
  lastSurvey,
  closedSurveys,
  teamSurveys,
  totalSurveys,
  totalTeamSurveys,
  surveysAdmin,
  users,
  surveyReports,
  teamSurveyReports,
  surveyReport,
  statistic,
  teamStatistic,
  filterAdminSurvey,
  filterUserSurvey,
  filterUserFeedback,
  callToActions,
  workforceSegment,
  businessUnit,
  customerExternal,
  surveyExcludeRules,
  transferHistory,
  lastSyncDate,
});

export default rootReducer;
