import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import Tooltip from '../Tooltip';
import RatingsStackedBar from '../RatingsStackedBar';

const ReportOverallResults = ({
  t,
  overallRating,
  progress,
  favorability,
  categories,
  selfAssessment,
  selfFavorability,
  selfCategories,
}) => {
  if (!overallRating) {
    return null;
  }

  const providers = Math.floor(100 * progress.count / progress.total);
  const score = Math.floor(favorability * 100);
  const selfScore = Math.floor(selfFavorability * 100);

  const overallRatings1 = _.get(overallRating, 1, []);
  const overallRatings2 = _.get(overallRating, 2, []);
  const overallRatings3 = _.get(overallRating, 3, []);
  const overallRatings4 = _.get(overallRating, 4, []);
  const overallRatings5 = _.get(overallRating, 5, []);

  return (
    <React.Fragment>
      <div className="report-overall-results report-box">
        <div className="report-box-title">
          {t('survey.label.overall_results')}
          {' '}
          <Tooltip title={t('tooltip.overall')} />
        </div>
        <RatingsStackedBar
          rating1={overallRatings1.length}
          rating2={overallRatings2.length}
          rating3={overallRatings3.length}
          rating4={overallRatings4.length}
          rating5={overallRatings5.length}
          selfAssessment={selfAssessment}
          selfScore={selfScore}
        >
          <div className="row progress-bar-box">
            <div className="col">
              <div className="progres-bar-label">
                {t('survey.label.providers')}
              </div>
              <div className="progress">
                <div
                  className="progress-bar providers"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${providers}%` }}
                  aria-valuenow={providers}
                  data-toggle="tooltip"
                  data-html="true"
                  title={t('tooltip.providers')}
                >
                  {progress.count}
                  {'/'}
                  {progress.total}
                </div>
              </div>
            </div>
            <div className="col" align="right">
              <div className="progres-bar-label">
                {t('survey.label.favorability')}
              </div>
              <div className="progress">
                <div
                  className="progress-bar favorability"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${score}%` }}
                  aria-valuenow={score}
                  data-toggle="tooltip"
                  data-html="true"
                  title={t('tooltip.favorable')}
                >
                  {score}
                  %
                </div>
              </div>
            </div>
          </div>
        </RatingsStackedBar>
      </div>
      {categories && categories.length > 0 && (
        <div className="report-overall-results report-box mt-3">
          <div className="row">
            {categories.sort((a, b) => a.category.localeCompare(b.category)).map((category) => {
              const categoryRatings1 = _.filter(
                overallRatings1,
                rating => category.questions.includes(rating.question),
              );
              const categoryRatings2 = _.filter(
                overallRatings2,
                rating => category.questions.includes(rating.question),
              );
              const categoryRatings3 = _.filter(
                overallRatings3,
                rating => category.questions.includes(rating.question),
              );
              const categoryRatings4 = _.filter(
                overallRatings4,
                rating => category.questions.includes(rating.question),
              );
              const categoryRatings5 = _.filter(
                overallRatings5,
                rating => category.questions.includes(rating.question),
              );
              const categorySelfAssessment = _.filter(
                selfAssessment,
                rating => category.questions.includes(rating.question),
              );
              const categoryScore = Math.floor(category.score * 100);
              const categorySelfAssessmentScore = Math.floor(_.get(_.filter(selfCategories, c => c.category === category.category), '0.score', 0) * 100);

              return (
                <div key={category.category} className="col overall-category-result">
                  <div className="report-box-title">{t(`survey.label.${category.category}`)}</div>
                  <RatingsStackedBar
                    rating1={categoryRatings1.length}
                    rating2={categoryRatings2.length}
                    rating3={categoryRatings3.length}
                    rating4={categoryRatings4.length}
                    rating5={categoryRatings5.length}
                    selfAssessment={categorySelfAssessment}
                    selfScore={categorySelfAssessmentScore}
                  >
                    <div className="overall-category-result__favorability" align="center">
                      <div className="progres-bar-label">
                        {t('survey.label.favorability')}
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar favorability"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${categoryScore}%` }}
                          aria-valuenow={categoryScore}
                          data-toggle="tooltip"
                          data-html="true"
                          title={t('tooltip.favorable')}
                        >
                          {categoryScore}
                          %
                        </div>
                      </div>
                    </div>
                  </RatingsStackedBar>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(ReportOverallResults);

ReportOverallResults.propTypes = {
  t: PropTypes.func.isRequired,
  overallRating: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
    isComment: PropTypes.bool,
    provider: PropTypes.string,
    question: PropTypes.string,
    rating: PropTypes.number,
  }))),
  progress: PropTypes.shape({
    total: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  favorability: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    score: PropTypes.number,
    question: PropTypes.string,
  })),
  selfAssessment: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
    isComment: PropTypes.bool,
    provider: PropTypes.string,
    question: PropTypes.string,
    rating: PropTypes.number,
  })),
  selfFavorability: PropTypes.number,
  selfCategories: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    score: PropTypes.number,
    question: PropTypes.string,
  })),
};

ReportOverallResults.defaultProps = {
  overallRating: null,
  categories: [],
  selfAssessment: [],
  selfFavorability: 0,
  selfCategories: [],
};
