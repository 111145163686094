import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import AutocompleteSelectField from '../../../components/AutocompleteSelectField';
import SubmitField from '../../../components/SubmitField/SubmitField';
import fetchUsers from '../../../redux-modules/user/actions';
import { loginAsAdmin } from '../../../redux-modules/auth/actions';

class AdminChooseUserForm extends Component {
  componentDidMount() {
    const {
      fetchUsers: fetchUsersAction,
      token,
    } = this.props;

    fetchUsersAction(token);
  }

  handleSubmit(values) {
    const { token, loginAsAdmin: loginAsAdminAction } = this.props;

    loginAsAdminAction(token, values.user.value).then(
      (data) => { window.location = data.payload.data.url; },
      () => {},
    );
  }

  render() {
    const {
      t,
      handleSubmit,
      users,
      lastSyncDate,
    } = this.props;

    return (
      <div className="admin-choose-user-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <Field
            name="user"
            label={t('user.label.select_user')}
            isMulti={false}
            options={users || []}
            component={AutocompleteSelectField}
          />
          <div className="buttons-group">
            <Field
              className="btn standard"
              inline
              name="submit"
              label={t('user.action.login')}
              component={SubmitField}
            />
          </div>
          {lastSyncDate && (
            <div className="form-group text-center">
              <span className={moment().diff(moment(lastSyncDate), 'days') > 0 ? 'text-danger' : 'text-success'}>
                Data feed was last updated
                {' '}
                {moment(lastSyncDate).fromNow()}
                {'.'}
              </span>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  token,
  users,
  lastSyncDate,
}) => ({
  token,
  users,
  lastSyncDate,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  loginAsAdmin,
}, dispatch);

export default withNamespaces()(reduxForm({
  form: 'adminChooseUserForm',
})(connect(mapStateToProps, mapDispatchToProps)(AdminChooseUserForm)));

AdminChooseUserForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
  })),
  lastSyncDate: PropTypes.string,
  fetchUsers: PropTypes.func.isRequired,
  loginAsAdmin: PropTypes.func.isRequired,
};

AdminChooseUserForm.defaultProps = {
  users: null,
  lastSyncDate: null,
};
