import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import TopBar from '../../components/TopBar';
import Footer from '../../components/Footer';
import ChooseLanguageForm from '../../containers/ChooseLanguageForm';
import disclaimerFiles from '../../utils/disclaimerFiles';

class WelcomePage extends Component {
  constructor(props) {
    super(props);

    this.state = { step: 1 };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleLanguageChange() {
    this.setState({ step: 2 });
  }

  handleConfirm() {
    const { history: { push } } = this.props;
    push('/#collapseFour');
  }

  renderLanguageChange() {
    const { t } = this.props;

    return (
      <div>
        <h2 align="center">
          {t('welcome.label.title')}
        </h2>
        <p>
          {t('welcome.label.paragraph_1')}
        </p>
        <p>
          {t('welcome.label.paragraph_2')}
        </p>
      </div>
    );
  }

  renderConfirm() {
    const { t, companyCode } = this.props;

    return (
      <div>
        <h2 align="center">
          {t('welcome.label.tc_title')}
        </h2>
        <p>
          {t('welcome.label.tc_paragraph_1')}
        </p>
        <ul>
          <li>
            {t('welcome.label.tc_paragraph_2')}
          </li>
          <li>
            {t('welcome.label.tc_paragraph_3')}
          </li>
          <li>
            {t('welcome.label.tc_paragraph_4')}
          </li>
          <li>
            {t('welcome.label.tc_paragraph_5')}
          </li>
          <li>
            {t('welcome.label.tc_paragraph_6')}
          </li>
        </ul>
        <p>
          {t('welcome.label.tc_paragraph_7')}
        </p>
        <p>
          {t('welcome.label.tc_paragraph_8')}
        </p>
        <div className="row">
          <div className="col text-center">
            <a
              href={`/files/disclaimer/${disclaimerFiles.getValue(companyCode)}.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('welcome.action.privacy_policy')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { step } = this.state;

    return (
      <div className="welcome-page">
        <TopBar />
        <main className="consent">
          <div className="container">
            <div className="content-box">
              {step === 1 ? this.renderLanguageChange() : this.renderConfirm()}
              <ChooseLanguageForm
                step={step}
                onLanguageChange={this.handleLanguageChange}
                onConfirm={this.handleConfirm}
              />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

const selectUserProfileCompanyCode = state => (state.userProfile ? state.userProfile.companyCode : '');

const mapStateToProps = state => ({
  companyCode: selectUserProfileCompanyCode(state),
});

export default connect(mapStateToProps, null)(withNamespaces()(WelcomePage));

WelcomePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  companyCode: PropTypes.string,
};

WelcomePage.defaultProps = {
  companyCode: '',
};
