import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import SelectField from '../../components/SelectField';
import SubmitField from '../../components/SubmitField';
import {
  selectSurveyType,
  selectSurveyTimeframe,
  selectSurveyUser,
} from '../../redux-modules/filter-survey/actions';

class UserSurveyFilters extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount() {
    const {
      selectSurveyType: selectSurveyTypeAction,
      selectSurveyTimeframe: selectSurveyTimeframeAction,
      selectSurveyUser: selectSurveyUserAction,
    } = this.props;

    selectSurveyTypeAction(null);
    selectSurveyTimeframeAction(null);
    selectSurveyUserAction(null);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSubmit(values) {
    const {
      selectSurveyType: selectSurveyTypeAction,
      selectSurveyTimeframe: selectSurveyTimeframeAction,
      selectSurveyUser: selectSurveyUserAction,
    } = this.props;

    const { type, timeframe, user } = values;

    selectSurveyTypeAction(type || null);
    selectSurveyTimeframeAction(timeframe || null);
    selectSurveyUserAction(user ? Number(user) : null);

    this.setState({ open: false });
  }

  render() {
    const {
      t,
      handleSubmit,
      userProfile,
      team,
      filterUserSurvey,
    } = this.props;
    const { open } = this.state;

    const teamUsers = _.get(userProfile.team, 'users', []);
    const scrumTeamUsers = _.get(userProfile.scrumTeam, 'users', []);
    let users = [...teamUsers, ...scrumTeamUsers];
    users = _.uniqBy(users, user => user.id);
    users = _.sortBy(users, user => user.name);

    const userOptions = users.map(user => ({
      value: user.id,
      label: user.name,
    }));
    const userNames = _.keyBy(userOptions, 'value');

    const selectedType = _.get(filterUserSurvey, 'type', null);
    const selectedTimeFrame = _.get(filterUserSurvey, 'timeframe', null);
    const selectedUser = _.get(filterUserSurvey, 'user', null);
    const selectedUserName = _.get(userNames, `${selectedUser}.label`, '');

    return (
      <div className="user-survey-filters fiter-box">
        <div className="row">
          <div className="col my-auto">
            <ul className="filter-selected">
              <li className={`${team ? '' : 'd-none'}`}>
                {!selectedUser
                  ? t('survey.placeholder.any_user')
                  : selectedUserName
                }
              </li>
              <li>
                {!selectedType
                  ? t('survey.placeholder.any_type')
                  : t(`survey.type.${selectedType}`)
                }
              </li>
              <li>
                {!selectedTimeFrame
                  ? t('survey.placeholder.all_time')
                  : t(`survey.label.${selectedTimeFrame}`)
                }
              </li>
            </ul>
          </div>
          <div className="col text-right my-auto">
            <button
              type="button"
              className="btn standard gray"
              onClick={this.handleOpen}
            >
              {t('survey.action.filter')}
            </button>
          </div>
        </div>
        <div className={`pop-up-filter-wrapper ${open ? 'open' : ''}`}>
          <div className="pop-up-filter">
            <div className="pop-up-filter-content">
              <span
                className="close-filter"
                role="button"
                tabIndex={0}
                onClick={this.handleClose}
                onKeyPress={this.handleClose}
              >
                {t('menu.label.close')}
                <i className="fas fa-times" />
              </span>
              <h3>
                {t('survey.action.filter')}
              </h3>
              <form
                noValidate
                onSubmit={handleSubmit(this.handleSubmit.bind(this))}
              >
                {team
                  ? (
                    <div>
                      <Field
                        name="user"
                        label={t('survey.label.user')}
                        showErrors={false}
                        placeholder={t('survey.placeholder.any_user')}
                        hasEmpty
                        options={userOptions}
                        component={SelectField}
                      />
                    </div>
                  )
                  : ''}
                <div>
                  <Field
                    name="type"
                    label={t('survey.label.type')}
                    showErrors={false}
                    placeholder={t('survey.placeholder.any_type')}
                    hasEmpty
                    options={[
                      {
                        label: t('survey.type.upwards_feedback'),
                        value: 'upwards_feedback',
                      },
                      {
                        label: t('survey.type.peer_feedback'),
                        value: 'peer_feedback',
                      },
                      {
                        label: t('survey.type.project_feedback'),
                        value: 'project_feedback',
                      },
                      {
                        label: t('survey.type.freestyle_feedback'),
                        value: 'freestyle_feedback',
                      },
                      {
                        label: t('survey.type.pod_feedback'),
                        value: 'pod_feedback',
                      },
                    ]}
                    component={SelectField}
                  />
                </div>
                <div>
                  <Field
                    name="timeframe"
                    label={t('survey.label.timeframe')}
                    showErrors={false}
                    placeholder={t('survey.placeholder.all_time')}
                    hasEmpty
                    options={[
                      {
                        label: t('survey.label.this_month'),
                        value: 'this_month',
                      },
                      {
                        label: t('survey.label.this_quarter'),
                        value: 'this_quarter',
                      },
                      {
                        label: t('survey.label.this_year'),
                        value: 'this_year',
                      },
                    ]}
                    component={SelectField}
                  />
                </div>
                <div className="form-group text-center">
                  <Field
                    name="submit"
                    label={t('survey.action.apply')}
                    component={SubmitField}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  filterUserSurvey,
  userProfile,
}) => ({ filterUserSurvey, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators({
  selectSurveyType,
  selectSurveyTimeframe,
  selectSurveyUser,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'userSurveyFilters',
})(UserSurveyFilters)));

UserSurveyFilters.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  filterUserSurvey: PropTypes.shape({
    type: PropTypes.string,
    timeframe: PropTypes.string,
  }),
  userProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    language: PropTypes.string,
    team: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      name: PropTypes.string,
      language: PropTypes.string,
    }),
  }),
  team: PropTypes.bool,
  selectSurveyType: PropTypes.func.isRequired,
  selectSurveyTimeframe: PropTypes.func.isRequired,
  selectSurveyUser: PropTypes.func.isRequired,
};

UserSurveyFilters.defaultProps = {
  filterUserSurvey: null,
  team: false,
  userProfile: null,
};
