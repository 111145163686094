import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import FeedbackProvideFormComponent from '../../components/FeedbackProvideForm';
import { createSelfAssessment, fetchSurvey } from '../../redux-modules/survey/actions';

class CreateSelfAssessmentForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      token,
      id,
      userProfile,
      fetchSurvey: fetchSurveyAction,
      onRedirect,
    } = this.props;

    fetchSurveyAction(token, id).then(
      (data) => {
        const survey = _.get(data.payload, 'data', null);
        if (survey && (survey.selfReflectionCompleted || survey.userId !== userProfile.id)) {
          onRedirect();
        }
      },
      (error) => {
        const data = JSON.parse(JSON.stringify(error));
        const code = _.get(data, 'response.data.code', 200);
        if (code === 403) {
          onRedirect();
        }
      },
    );
  }

  handleSubmit(answers) {
    const {
      id,
      token,
      createSelfAssessment: createSelfAssessmentAction,
    } = this.props;

    return createSelfAssessmentAction(token, id, answers);
  }

  render() {
    const { surveys, id } = this.props;

    const survey = _.get(surveys, id, null);

    return survey && survey.status === 'active' && !survey.selfReflectionCompleted && survey.questions
      ? (
        <FeedbackProvideFormComponent
          showBack
          questions={survey.questions.slice(0, 11)}
          year={survey.year}
          quarter={survey.quarter}
          type={survey.type}
          name={survey.fullName}
          dueDate={moment(survey.endDate)}
          userName={survey.fullName}
          onSubmit={this.handleSubmit}
          selfAssessment
        />
      )
      : <div />;
  }
}

const mapStateToProps = ({ token, surveys, userProfile }) => ({ token, surveys, userProfile });

const mapDispatchToProps = dispatch => bindActionCreators(
  { createSelfAssessment, fetchSurvey },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateSelfAssessmentForm);

CreateSelfAssessmentForm.propTypes = {
  id: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  surveys: PropTypes.shape({}),
  userProfile: PropTypes.shape({}),
  createSelfAssessment: PropTypes.func.isRequired,
  fetchSurvey: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

CreateSelfAssessmentForm.defaultProps = {
  surveys: null,
  userProfile: null,
};
