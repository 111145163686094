/* global navigator, document, Blob */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import './download-csv.css';

const DownloadCSV = ({ label, data, filename }) => {
  const csvString = useMemo(() => {
    const isWindows = navigator.platform.startsWith('Win');
    const newline = isWindows ? '\r\n' : '\n';

    return data.map(line => line.join(',')).join(newline);
  }, [data]);

  const handleDownloadClick = useCallback(() => {
    const csvData = new Blob([csvString], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${filename}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [csvString]);

  return (
    <button
      className="download-csv-download-link"
      type="button"
      onClick={handleDownloadClick}
    >
      {label}
    </button>
  );
};

export default DownloadCSV;

DownloadCSV.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  filename: PropTypes.string.isRequired,
};
