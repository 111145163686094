import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import _ from 'lodash';

import SelectField from '../../../components/SelectField';
import FileField from '../../../components/FileField';
import SubmitField from '../../../components/SubmitField';
import DownloadCSV from '../../components/DownloadCSV';
import {
  fetchSurveyExcludeRules,
  importSurveyExcludeRule,
} from '../../../redux-modules/survey-exclude-rule/actions';
import validators from '../../../utils/validators';

class AdminSurveyExcludeRuleImportForm extends Component {
  constructor(props) {
    super(props);

    this.handleCancel = this.handleCancel.bind(this);
  }

  handleSubmit(values) {
    const {
      fetchSurveyExcludeRules: fetchSurveyExcludeRulesAction,
      importSurveyExcludeRule: importSurveyExcludeRuleAction,
      onSubmit,
      type,
    } = this.props;

    return importSurveyExcludeRuleAction(values.type, values.file)
      .then(() => fetchSurveyExcludeRulesAction(type).then(() => onSubmit()))
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data', '');
        if (errorMessage) {
          throw new SubmissionError({ file: errorMessage });
        }
      });
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  render() {
    const {
      t,
      handleSubmit,
    } = this.props;

    return (
      <div className="admin-create-survey-form">
        <form
          noValidate
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className="form-group mb-2">
            <Field
              name="type"
              label={t('survey.label.type')}
              placeholder={t('survey.placeholder.any_type')}
              options={[
                {
                  label: t('survey.type.upwards_feedback'),
                  value: 'upwards_feedback',
                },
                {
                  label: t('survey.type.peer_feedback'),
                  value: 'peer_feedback',
                },
              ]}
              component={SelectField}
            />
          </div>
          <Field
            name="file"
            label={t('survey.label.file')}
            placeholder={t('survey.placeholder.any_type')}
            accept=".csv"
            component={FileField}
          />
          <div className="form-group mb-2">
            <DownloadCSV
              label="Example import file"
              data={[
                ['100', 'nikola.isailovic+100@testbpractice.co'],
                ['', 'nikola.isailovic+102@testbpractice.co'],
                ['00000100', ''],
              ]}
              filename="survey-exlude-rules-example-file"
            />
          </div>
          <div className="form-group text-center mb-2 mt-4">
            <button
              className="btn standard gray"
              type="button"
              onClick={this.handleCancel}
            >
              {t('survey_exclude_rule.action.cancel')}
            </button>
            <Field
              inline
              name="submit"
              label={t('survey_exclude_rule.action.import')}
              component={SubmitField}
            />
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector('adminSurveyExcludeRulesFilters');

const validate = (values, { t }) => {
  const errors = {};

  if (!validators.required(values.type)) {
    errors.type = t('survey.error.type_required');
  }
  if (!validators.required(values.file)) {
    errors.file = t('survey_exclude_rule.error.file_required');
  }

  return errors;
};

const mapStateToProps = state => ({
  initialValues: {
    type: '',
    file: '',
  },
  type: selector(state, 'type'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchSurveyExcludeRules,
  importSurveyExcludeRule,
}, dispatch);

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  validate,
  form: 'adminSurveyExcludeRuleImportForm',
  enableReinitialize: true,
})(AdminSurveyExcludeRuleImportForm)));

AdminSurveyExcludeRuleImportForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchSurveyExcludeRules: PropTypes.func.isRequired,
  importSurveyExcludeRule: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string,
};

AdminSurveyExcludeRuleImportForm.defaultProps = {
  type: null,
};
